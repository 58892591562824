import { Storage } from '@ionic/storage';
const storage = new Storage();
storage.create();

export default storage;

export const loadData =
  (str: string) =>
  ({ onSet, trigger, setSelf }: any) => {
    const load = async () => {
      const data = await storage.get(str);
      if (data) {
        setSelf(data);
      }
    };
    if (trigger === 'get') {
      load();
    }
    onSet((data: any) => {
      storage.set(str, data);
    });
  };
