import { useMutation } from '@tanstack/react-query';

import { getFirestore, collection, doc, updateDoc } from 'firebase/firestore';
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import { type Tag } from '../interface/Folder';

interface uploadImgProps {
  photo: Blob;
  cover: Blob;
  uid: string;
}

const uploadImg = async (photo: Blob, cover: Blob, uid: string) => {
  const storage = getStorage();
  const db = getFirestore();
  const storageRef = ref(storage, 'profili/' + uid + '/' + uid);

  const uploadTask = uploadBytesResumable(storageRef, photo);
  uploadTask.on(
    'state_changed',
    (snapshot) => {},
    (error) => {
      throw new Error(error.message);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        updateDoc(doc(collection(db, 'profili'), uid), {
          'dati.foto': downloadURL,
        });
      });
    }
  );

  // cover
  const storageRef2 = ref(storage, 'profili/' + uid + '/cover');

  const uploadTask2 = uploadBytesResumable(storageRef2, cover);
  uploadTask2.on(
    'state_changed',
    (snapshot) => {},
    (error) => {
      throw new Error(error.message);
    },
    () => {
      getDownloadURL(uploadTask2.snapshot.ref).then(async (downloadURL) => {
        updateDoc(doc(collection(db, 'profili'), uid), {
          'dati.cover': downloadURL,
        });
      });
    }
  );
};

export const uploadImgPost = async (
  photo: Blob,
  uid: string,
  tag: Tag,
  id: string
) => {
  const storage = getStorage();
  const db = getFirestore();
  const storageRef = ref(
    storage,
    'profili/' + uid + '/' + tag + '/' + id + '/' + id
  );

  const uploadTask = uploadBytesResumable(storageRef, photo);
  uploadTask.on(
    'state_changed',
    (snapshot) => {},
    (error) => {
      throw new Error(error.message);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        updateDoc(doc(collection(db, 'profili'), uid, tag, id), {
          img: downloadURL,
        });
      });
    }
  );
};

export const useMutationUploadImg = () => {
  return useMutation(async ({ photo, cover, uid }: uploadImgProps) => {
    await uploadImg(photo, cover, uid);
  });
};

export const uploadImgFolder = async (
  photo: Blob,
  uid: string,
  path: string
) => {
  const storage = getStorage();
  const db = getFirestore();
  const storageRef = ref(storage, 'profili/' + uid + '/' + path + '/img');

  const uploadTask = uploadBytesResumable(storageRef, photo);
  uploadTask.on(
    'state_changed',
    (snapshot) => {},
    (error) => {
      throw new Error(error.message);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        updateDoc(doc(collection(db, 'profili'), uid, path), {
          imgBackground: downloadURL,
        });
      });
    }
  );
};

export const uploadImgDatabasePost = async (
  photo: Blob,
  title: string,
  tag: Tag,
  uid: string,
  invalidateQuery: (uid: string) => void
) => {
  const storage = getStorage();
  const db = getFirestore();
  const storageRef = ref(storage, `${tag}/${title}/${title}`);

  const uploadTask = uploadBytesResumable(storageRef, photo);
  uploadTask.on(
    'state_changed',
    (snapshot) => {},
    (error) => {
      throw new Error(error.message);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
        await updateDoc(doc(collection(db, tag), title), {
          img: downloadURL,
        });
        invalidateQuery(uid);
      });
    }
  );
};
