import { useRecoilState, useRecoilValue } from 'recoil';
import friendsAuth, { atomFriendsUser } from '../store/friendsAuth';

export const useFriendsAuth = () => {
  return useRecoilState(friendsAuth);
};
export const useFriendsAuthValue = () => {
  return useRecoilValue(friendsAuth);
};

export const useFriendsUserState = () => {
  return useRecoilState(atomFriendsUser);
};

export const useFriendsUserValue = () => {
  return useRecoilValue(atomFriendsUser);
};
