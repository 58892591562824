/* eslint-disable @typescript-eslint/promise-function-async */

import { home, planet, search } from 'ionicons/icons';
import { lazy, useMemo, useCallback } from 'react';
import type Language from '../interface/Language';
const Home = lazy(() => import('../pages/Home'));
const EmailNotVerified = lazy(() => import('../pages/EmailNotVerified'));
const Login = lazy(() => import('../pages/Login'));
const Register = lazy(() => import('../pages/Register'));
const Feed = lazy(() => import('../pages/Feed'));
const PersonalFeed = lazy(() => import('../pages/PersonalFeed'));
const Profile = lazy(() => import('../pages/Profile'));
const Chat = lazy(() => import('../pages/Chat'));
const Research = lazy(() => import('../pages/Research'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const ImportBooks = lazy(() => import('../pages/ImportBooks'));
const Notifications = lazy(() => import('../pages/Notifications'));
const ReportProblem = lazy(() => import('../pages/ReportProblem'));
const DeleteAccount = lazy(() => import('../pages/DeleteAccount'));
const Customize = lazy(() => import('../pages/Customize'));
const ModPost = lazy(() => import('../pages/ModPost'));
const Comments = lazy(() => import('../pages/Comments'));
const AddPost = lazy(() => import('../pages/AddPost'));
const MessageChat = lazy(() => import('../components/MessageChat'));
const Like = lazy(() => import('../pages/Like'));
const DetailElement = lazy(() => import('../pages/DetailElement'));
const ModProfile = lazy(() => import('../pages/ModProfile'));
const FriendsComponent = lazy(
  async () => await import('../pages/FriendsComponent')
);
const AddFolder = lazy(() => import('../pages/AddFolder'));
const Statistics = lazy(() => import('../pages/Statistics'));
const Posts = lazy(() => import('../pages/Posts'));
const FeedbackForm = lazy(() => import('../pages/FeedbackForm'));

interface ArrRoute {
  Component: React.FC;
  path: string;
  auth: boolean;
}

interface ArrTabBar {
  icon?: string;
  path: string;
  tab: string;
  label?: string;
  auth: boolean;
  image?: string;
}
const useRouterMapped = () => {
  const arrRoute: ArrRoute[] = useMemo(
    () => [
      {
        Component: Home,
        path: '/home',
        auth: false,
      },
      {
        Component: Login,
        path: '/login',
        auth: false,
      },
      {
        Component: Register,
        path: '/register',
        auth: false,
      },
      {
        Component: ReportProblem,
        path: '/reportProblem',
        auth: false,
      },
      {
        Component: EmailNotVerified,
        path: '/emailNotVerified',
        auth: false,
      },
      {
        Component: Profile,
        path: '/profile/:uid',
        auth: true,
      },
      {
        Component: Feed,
        path: '/feed',
        auth: true,
      },
      {
        Component: PersonalFeed,
        path: '/personalFeed',
        auth: true,
      },
      {
        Component: Chat,
        path: '/chat',
        auth: true,
      },
      {
        Component: Research,
        path: '/research',
        auth: true,
      },
      {
        Component: ChangePassword,
        path: '/changePassword',
        auth: true,
      },
      {
        Component: ImportBooks,
        path: '/importBooks',
        auth: true,
      },
      {
        Component: Notifications,
        path: '/notifications',
        auth: true,
      },
      {
        Component: ReportProblem,
        path: '/reportProblem',
        auth: true,
      },
      {
        Component: DeleteAccount,
        path: '/deleteAccount',
        auth: true,
      },
      {
        Component: Customize,
        path: '/customize',
        auth: true,
      },
      {
        Component: ModPost,
        path: '/modPost',
        auth: true,
      },
      {
        Component: AddPost,
        path: '/addPost',
        auth: true,
      },
      {
        Component: Comments,
        path: '/comments/:uid/:tag/:idDocumentPost',
        auth: true,
      },
      {
        Component: MessageChat,
        path: '/message/:uid',
        auth: true,
      },
      {
        Component: Like,
        path: '/like/:uid/:tag/:idDocumentPost',
        auth: true,
      },
      {
        Component: DetailElement,
        path: '/detailElement',
        auth: true,
      },
      {
        Component: ModProfile,
        path: '/modProfile',
        auth: true,
      },
      {
        Component: FriendsComponent,
        path: '/friends/:uidUser',
        auth: true,
      },
      {
        Component: AddFolder,
        path: '/addFolder',
        auth: true,
      },
      {
        Component: Statistics,
        path: '/statistics',
        auth: true,
      },
      {
        Component: Posts,
        path: '/posts/:uid/:tag',
        auth: true,
      },
      {
        Component: FeedbackForm,
        path: '/feedback',
        auth: true,
      },
    ],
    []
  );

  const arrTabBar = useCallback(
    (language: Language, uid: string, image?: string): ArrTabBar[] => [
      {
        path: '/login',
        tab: 'login',
        auth: false,
        label: language?.accedi ?? 'Login',
      },
      {
        path: '/home',
        tab: 'home',
        auth: false,
        icon: home,
      },
      {
        path: '/register',
        tab: 'register',
        auth: false,
        label: language?.registrati ?? 'Register',
      },
      {
        icon: planet,
        path: '/feed',
        tab: 'feed',
        auth: true,
      },
      {
        path: `/profile/${uid}`,
        tab: 'profile',
        auth: true,
        image,
      },
      {
        icon: search,
        path: '/research',
        tab: 'research',
        auth: true,
      },
    ],
    []
  );

  return { arrRoute, arrTabBar };
};

export default useRouterMapped;
