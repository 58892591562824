import { Keyboard } from '@capacitor/keyboard';
import { useCallback } from 'react';

interface Props {
  setHidden: (data: boolean) => void;
}
const useKeyboard = () => {
  const keyboardListner = useCallback(({ setHidden }: Props) => {
    if (Keyboard) {
      Keyboard.addListener('keyboardWillShow', () => {
        setHidden(true);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setHidden(false);
      });
    }
  }, []);

  return { keyboardListner };
};
export default useKeyboard;
