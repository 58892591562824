import { atom } from 'recoil';
import type AuthData from '../../interface/AuthData';
import { loadData } from '../storage';

const authStore = atom<AuthData>({
  key: 'authStore',
  default: {
    user: {
      uid: '',
      email: '',
      emailVerified: false
    },
    authenticated: false,
  },
  effects: [loadData('auth')],
});

export default authStore;
