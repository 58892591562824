import { atom } from 'recoil';
import { type PostFolder } from '../interface/Folder';
import type Post from '../interface/Post';
interface Props {
  post: Post;
  oldDataFolder?: PostFolder;
  func: 'copy' | 'move';
  originalRef: string;
  idDocument: string;
  copyRef?: boolean;
  copyRefByMyPost?: boolean;
}

interface AtomProps {
  posts?: Props[];
  enableAddButton?: boolean;
  ref: string;
}
const atomMovePost = atom<AtomProps>({
  key: 'atomMovePost',
  default: {
    ref: '',
  },
});
export default atomMovePost;
