import React, {
  memo,
  useContext,
  useState,
  useEffect,
  useMemo,
  Suspense,
} from 'react';
import { Route, useHistory } from 'react-router-dom';
import { AuthContext, LanguageContext } from '../store/context';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import '../style/TabBar.scss';
import useRouterMapped from '../hooks/useRouteMapped';
import { ProfileTab } from '../components/ProfileTab';
import useKeyboard from '../hooks/useKeyboard';
import { isWeb } from '../utility';
import DelayedMount from '../components/DelayedMount';
import FullScreenLoading from '../components/FullScreenLoading';

const Tabs: React.FC = () => {
  const [tabBarHidden, setTabBarHidden] = useState(true);
  const auth = useContext(AuthContext);
  const language = useContext(LanguageContext);
  const uid = useMemo(() => auth?.user?.uid, [auth?.user?.uid]);
  const { keyboardListner } = useKeyboard();
  const { arrRoute, arrTabBar } = useRouterMapped();
  const history = useHistory();

  const routeMapped = useMemo(
    () =>
      arrRoute
        ?.filter(
          (el) => el.auth === (auth?.authenticated && auth?.user?.emailVerified)
        )
        ?.map(({ Component, path }) => (
          <Route
            key={path}
            exact
            path={path}
            render={() => (
              <Suspense
                fallback={
                  <DelayedMount delay={100}>
                    <FullScreenLoading />
                  </DelayedMount>
                }
              >
                <Component />
              </Suspense>
            )}
          />
        )) ?? [],
    [arrRoute, auth?.authenticated, auth?.user?.emailVerified]
  );
  const tabBarMapped = useMemo(
    () =>
      arrTabBar(language, uid)
        ?.filter(
          (el) => el.auth === (auth?.authenticated && auth?.user?.emailVerified)
        )
        ?.map(({ path, tab, icon, label, image }) => (
          <IonTabButton
            tab={tab}
            href={path}
            className="transparent"
            key={path}
          >
            {icon && <IonIcon icon={icon} />}
            {label && <IonLabel class="text-lg m-0">{label}</IonLabel>}
            {tab === 'profile' && <ProfileTab />}
          </IonTabButton>
        )) ?? [],
    [arrTabBar, auth?.authenticated, auth?.user?.emailVerified, language, uid]
  );

  useEffect(() => {
    if (
      !routeMapped?.map(({ key }) => key).includes(history.location.pathname) ||
      !auth?.user?.emailVerified
    ) {
      if (auth?.authenticated && auth?.user?.uid) {
        history.push(
          auth?.user?.emailVerified
            ? `/profile/${auth?.user?.uid}`
            : '/emailNotVerified'
        );
      } else {
        history.push('/home');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, routeMapped]);

  useEffect(() => {
    if (!isWeb) {
      keyboardListner({ setHidden: setTabBarHidden });
    }
  }, [keyboardListner]);

  return (
    <IonTabs>
      <IonRouterOutlet key={routeMapped?.length}>{routeMapped}</IonRouterOutlet>
      <IonTabBar
        id="my-tabbar"
        slot="bottom"
        className="nav "
        hidden={tabBarHidden}
        key={routeMapped?.length}
      >
        {tabBarMapped}
      </IonTabBar>
    </IonTabs>
  );
};
export default memo(Tabs);
