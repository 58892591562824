import { memo, useState } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import Tabs from './Tabs';
import MovePost from '../components/MovePost';
import PageLoading from '../pages/PageLoading';

const AppRoutes: React.FC = () => {
  const [loader, setLoader] = useState(true);
  setTimeout(() => {
    setLoader(false);
  }, 1000);
  return (
    <IonReactRouter>
      {loader && <PageLoading />}
      <MovePost />
      <Tabs />
    </IonReactRouter>
  );
};

export default memo(AppRoutes);
