import { useRecoilState, useRecoilValue } from 'recoil';
import { useQueryUserData, useQueryUserDataProfileTab } from '../api/userData';
import atomUserData from '../store/userData/userData';

const useUserDataValue = () => {
  return useRecoilValue(atomUserData);
};
export default useUserDataValue;

export const useUserData = () => {
  return useRecoilState(atomUserData);
};

export const getUserData = (uid: string, enabled?: boolean) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQueryUserData(uid, enabled);
};
export const getUserDataProfileTab = (uid: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQueryUserDataProfileTab(uid);
};
