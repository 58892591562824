/* eslint-disable react-hooks/rules-of-hooks */
import {
  useMutation,
  useQuery,
  type UseQueryResult,
} from '@tanstack/react-query';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  serverTimestamp,
  type Timestamp,
  where,
} from 'firebase/firestore';
import axios from 'axios';
import type Post from '../interface/Post';
import {
  type BookVolumes,
  type Volume,
  type Volumes,
} from '../interface/GoogleBooks';
import { type Tag } from '../interface/Folder';
import { type SearchTvdb } from '../interface/Tvdb';
import { type CategoryTvdb } from '../interface/CategoryTvdb';
import { type VideogameApi } from '../interface/VideogamesApi';
import { type CategoryGames } from '../interface/CategoryGames';
import { type FriendsDetail } from '../interface/Friends';
import { type ImageByCustomSearch } from '../interface/ImageByCustomSearch';

interface searchPostProps {
  tag: Tag;
}

interface getItemsProps {
  item: string;
  language: string;
  type: Tag | 'account';
  barcode?: string;
}

interface getFriendProps {
  nickname: string;
}

const searchPostRaccomended = async ({
  tag,
}: searchPostProps): Promise<Post[]> => {
  const db = getFirestore();
  const q = query(collection(db, tag), orderBy('data', 'desc'), limit(30));
  const res = await getDocs(q);
  return res.docs.map((x) => {
    return { ...x.data(), idPost: x.id } as Post;
  });
};

const getBooks = async ({
  item,
  language,
}: getItemsProps): Promise<Post[] | undefined> => {
  const res = await axios.post(
    `https://us-central1-appbook-bf2d3.cloudfunctions.net/getBooks?libro=${item}&language=${language}` // cambiare libro con book
  );
  const data: Volumes = res.data;
  const items: BookVolumes[] = data.items;
  if (!items) {
    return undefined;
  }
  /* const volume: Volume[] = [];
  for await (const vol of items) {
    volume.push((await getBookData(vol.id)) as Volume);
  } */
  return items.map((x) => covertVolumeToPost(x)); // getUserData(y.id) as Volume
};

const covertVolumeToPost = (volume: Volume | BookVolumes): Post => {
  if (volume.volumeInfo.description !== undefined) {
    const temp = document.createElement('div');
    temp.innerHTML = volume.volumeInfo.description;
    volume.volumeInfo.description = temp.textContent ?? temp.innerText ?? '';
  }
  const post: Post = {
    titolo: volume.volumeInfo.title.trim() ?? '',
    autore: !volume.volumeInfo.authors
      ? ''
      : typeof volume.volumeInfo.authors === 'string'
      ? volume.volumeInfo.authors
      : volume.volumeInfo.authors.join(', ').trim(),
    descrizione: volume.volumeInfo.description ?? '',
    dataPost: serverTimestamp() as Timestamp,
    tag: 'libri',
    idPost: volume.volumeInfo.title.trim(),
    img: volume.volumeInfo?.imageLinks?.thumbnail
      ? volume.volumeInfo.imageLinks.thumbnail
      : require('../assets/img/error.png'),
  };
  return post;
};

const covertSearchTvdbToPost = (
  item: SearchTvdb,
  lang: string,
  tag: Tag
): Post => {
  const post: Post = {
    titolo: item.translations?.[lang === 'it' ? 'ita' : 'eng']
      ? item.translations[lang === 'it' ? 'ita' : 'eng']
      : item.name ?? '',
    autore: item.studios ? item.studios.join(', ') ?? '' : '',
    descrizione: item.overviews?.[lang === 'it' ? 'ita' : 'eng']
      ? item.overviews[lang === 'it' ? 'ita' : 'eng']
      : item.overview ?? '',
    dataPost: serverTimestamp() as Timestamp,
    tag,
    idPost: item.name ?? '',
    img: item.thumbnail ?? require('../assets/img/error.png'),
    categoria: item.genres
      ? item.genres.map((x) => x.replace(' ', '_') as CategoryTvdb)
      : [],
    idTvdb: item.tvdb_id ?? '',
  };
  return post;
};

const convertVideogamesApiToPost = (game: VideogameApi): Post => {
  const post: Post = {
    titolo: game.name ? game.name : '',
    autore: game.developers
      ? game.developers.map((x) => x.name).join(', ')
      : '',
    descrizione: game.description_raw ? game.description_raw : '',
    dataPost: serverTimestamp() as Timestamp,
    tag: 'videogiochi',
    idPost: game.name ?? '',
    img: game.background_image
      ? game.background_image
      : require('../assets/img/error.png'),
    categoria: game.genres
      ? game.genres.map((x) => x.name as CategoryGames)
      : [],
  };
  return post;
};

export const getBookData = async (id: string): Promise<Volume | undefined> => {
  const res = await axios.post(
    `https://us-central1-appbook-bf2d3.cloudfunctions.net/getUserData?id=${id}`
  );
  return res.data as Volume;
};

const getBarCode = async (isbn: string): Promise<Volume | undefined> => {
  const res = await axios.post(
    `https://us-central1-appbook-bf2d3.cloudfunctions.net/getBarCode?isbn=${isbn}`
  );
  const data: Volumes = res.data;

  return data.items
    ? ((await getBookData(data.items[0].id)) as Volume)
    : undefined;
};
/*
const getBook = async (id: string) => {
  return await axios
    .post(
      `https://us-central1-appbook-bf2d3.cloudfunctions.net/getUserData?id=${id}`
    )
    .then(async (val) => {
      try {
        return val.data;
      } catch {
        return [];
      }
    });
}; */

let tokenTvdb = '';
export const getTokenTvdb = async () => {
  await axios
    .post('https://us-central1-appbook-bf2d3.cloudfunctions.net/getTokenTvdb')
    .then(async (val) => {
      try {
        tokenTvdb = val.data.data.token;
      } catch {
        tokenTvdb = '';
      }
    });
};

const searchTvdb = async (titolo: string, tipo: string) => {
  return await axios
    .post(
      `https://us-central1-appbook-bf2d3.cloudfunctions.net/searchTvdb?token=${tokenTvdb}&title=${titolo}&type=${tipo}`
    )
    .then(async (val) => {
      try {
        const items = val.data.data;
        return items;
      } catch {
        return [];
      }
    });
};
export interface TvdbEpisodes {
  id: number;
  seriesId: number;
  name: string;
  aired: Date;
  runtime: number;
  nameTranslations: null;
  overview: string;
  overviewTranslations: null;
  image: string;
  imageType: number;
  isMovie: number;
  seasons: null;
  number: number;
  seasonNumber: number;
  lastUpdated: Date;
  finaleType: null;
  year: string;
}
interface searchTvdbEpisodesProps {
  tipo: string;
  lang: 'ita' | 'eng' | 'fra' | 'spa' | 'pt';
  titolo: string;
  id?: string;
}
const searchTvdbEpisodes = async ({
  lang,
  tipo,
  id,
  titolo
}: searchTvdbEpisodesProps): Promise<TvdbEpisodes[]> => {
  let idTvdb = id;
  if (!idTvdb) {
    idTvdb = (await searchTvdb(titolo, 'series'))[0]?.tvdb_id ?? '';
  }
  const url = `https://api4.thetvdb.com/v4/${tipo}/${idTvdb}/episodes/default/${
    lang ?? 'eng'
  }?page=0`;
  return await axios
    .get(url, {
      responseType: 'json',
      headers: {
        Authorization: 'Bearer ' + tokenTvdb,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(async (val) => {
      return (val?.data?.data?.episodes ?? []) as TvdbEpisodes[];
    })
    .catch(() => {
      return [];
    });

  /* await axios
    .post(
      `https://us-central1-appbook-bf2d3.cloudfunctions.net/searchTvdbExtended?token=${tokenTvdb}&id=${id}&type=${tipo}`
    )
    .then(async (val) => {
      try {
        const items = val.data.data;
        console.log(items);
        return items;
      } catch (err) {
        return [];
      }
    }); */
}; /**/

const getGame = async (game: string) => {
  return await axios
    .post(
      `https://us-central1-appbook-bf2d3.cloudfunctions.net/getGame?game=${game}`
    )
    .then(async (val) => {
      try {
        const items = val.data.results;
        return items;
      } catch {
        return [];
      }
    });
};

const getGameById = async (id: string) => {
  return await axios
    .post(
      `https://us-central1-appbook-bf2d3.cloudfunctions.net/getGameById?id=${id}`
    )
    .then(async (val) => {
      try {
        const items = val.data;
        return items;
      } catch {
        return [];
      }
    });
};
/*
const getCategoryGames = async () => {
  return await axios
    .post(
      'https://us-central1-appbook-bf2d3.cloudfunctions.net/getCategoryGames'
    )
    .then(async (val) => {
      try {
        const items = val.data;
        return items;
      } catch {
        return [];
      }
    });
}; */

const getFriendByNickname = async ({
  nickname,
}: getFriendProps): Promise<FriendsDetail[]> => {
  const db = getFirestore();
  const q = query(
    collection(db, 'profili'),
    where('dati.nickname', '==', nickname)
  );
  const res = await getDocs(q);
  return res.docs.map((x) => {
    const val = x.data();
    return {
      uid: val.dati.uid,
      avatar: val.dati.foto,
      nickname: val.dati.nickname ?? '',
      name: val.dati.nome ?? '',
    } as FriendsDetail;
  });
};

const getImageByCustomSearch = async (query: string): Promise<string[]> => {
  try {
    const key = 'AIzaSyBxbbGuPIl6QdMCmtnr3fC1-ah3LhwTObg';
    const url = `https://www.googleapis.com/customsearch/v1?key=${key}&cx=950c51076ba2b432a&q=${query}&searchType=image&imgType=photo`;
    const response = await axios.get(url);

    if (!response.status) {
      throw new Error('Errore nella ricerca.');
    }

    const data = (await response.data) as ImageByCustomSearch;
    const imageLinks = data?.items?.map((item) => item?.link) || [];
    return imageLinks;
  } catch (error) {
    // console.error(error);
    return [];
  }
};

const getTextFromImageByGoogleCloudVision = async (base64String: string) => {
  const apiKey = 'AIzaSyBxbbGuPIl6QdMCmtnr3fC1-ah3LhwTObg'; // Sostituisci con la tua chiave API
  try {
    const response = await axios.post(
      `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`,
      {
        requests: [
          {
            image: {
              content: base64String,
            },
            features: [
              {
                maxResults: 50,
                model: 'builtin/latest',
                type: 'DOCUMENT_TEXT_DETECTION',
              },
            ],
          },
        ],
      }
    );

    const fullTextAnnotation = response.data?.responses[0]?.fullTextAnnotation;
    const text = fullTextAnnotation?.text ?? '';
    return text;
  } catch (error) {
    //  console.error(error);
  }
};

const getTitleFromGoogleCloudVision = async (base64String: string) => {
  const apiKey = 'AIzaSyBxbbGuPIl6QdMCmtnr3fC1-ah3LhwTObg'; // Sostituisci con la tua chiave API
  try {
    const response = await axios.post(
      `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`,
      {
        requests: [
          {
            image: {
              content: base64String,
            },
            features: [
              {
                type: 'WEB_DETECTION',
                maxResults: 10,
              },
            ],
          },
        ],
      }
    );

    const webDetection = response.data?.responses[0]?.webDetection;
    const title = webDetection?.bestGuessLabels[0]?.label;
    return title;
  } catch (error) {
    //  console.error(error);
  }
};

export const querySearchPostRaccomended = (
  props: searchPostProps
): UseQueryResult<Post[]> => {
  return useQuery(
    ['searchPostRaccomended', props.tag],
    async () => {
      return await searchPostRaccomended(props);
    },
    { refetchInterval: false, retry: false }
  );
};

const switchQueryGetItems = async (
  props: getItemsProps
): Promise<Post[] | undefined> => {
  switch (props.type) {
    case 'libri': {
      if (!props.barcode) return await getBooks(props);
      else {
        const res = await getBarCode(props.barcode);
        return res ? [covertVolumeToPost(res)] : undefined;
      }
    }
    case 'serietv': {
      const res: SearchTvdb[] = await searchTvdb(props.item, 'series');
      const resConverted: Post[] = [];
      for await (const val of res) {
        resConverted.push(
          covertSearchTvdbToPost(val, props.language, props.type)
        );
      }
      return resConverted;
    }
    case 'film': {
      const res: SearchTvdb[] = await searchTvdb(props.item, 'movie');
      const resConverted: Post[] = [];
      for await (const val of res) {
        resConverted.push(
          covertSearchTvdbToPost(val, props.language, props.type)
        );
      }
      return resConverted;
    }
    case 'videogiochi': {
      const res: VideogameApi[] = await getGame(props.item);
      const resConverted: Post[] = [];
      for await (const item of res) {
        resConverted.push(
          convertVideogamesApiToPost(await getGameById(item.id.toString())) // cambiare con  convertVideogamesApiToPost(item)
        );
      }
      return resConverted;
    }
  }
};

export const queryGetItems = (
  props: getItemsProps
): UseQueryResult<Post[] | undefined> => {
  return useQuery(
    ['GetItems', props.type, props.item],
    async (): Promise<Post[] | undefined> => {
      return await switchQueryGetItems(props);
    },
    {
      enabled: true,
      refetchInterval: false,
      retry: false,
    }
  );
};

export const useBarCode = (isbn: string): UseQueryResult<Post | undefined> => {
  return useQuery(['barCode', isbn], async (): Promise<Post | undefined> => {
    const res = (await getBarCode(isbn)) as Volume;
    if (!res) {
      throw new Error('item not found');
    }
    return covertVolumeToPost(res);
  });
};

export const queryGetFriendByNickname = (
  props: getFriendProps
): UseQueryResult<FriendsDetail[]> => {
  return useQuery(
    ['getFriendByNickname', props.nickname],
    async () => {
      return await getFriendByNickname(props);
    },
    { refetchInterval: false, retry: false }
  );
};

export const useImageByCustomSearchMutation = () =>
  useMutation(getImageByCustomSearch);

export const useTitleFromGoogleCloudVisionMutation = () =>
  useMutation(getTitleFromGoogleCloudVision);

export const useTextFromImageByGoogleCloudVision = () =>
  useMutation(getTextFromImageByGoogleCloudVision);

export const useGetEpisodes = () => useMutation(searchTvdbEpisodes);
