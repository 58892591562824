import { useCallback, useContext } from 'react';
import {
  useMutationDeletePost,
  useMutationEditPost,
  useMutationSetPost,
} from '../api/postApi';
import useToast from './useToast';
import { AuthContext } from '../store/context';
import { showInterstitial } from '../utility';
import type Post from '../interface/Post';
import { type PostFolder } from '../interface/Folder';

interface UploadPostProps {
  post: Post;
  ref: string;
  idDocument?: string;
  originalRef?: string;
  deleteImg?: boolean;
  deletePost?: boolean;
  photoBlob?: Blob;
  editPost?: boolean;
  deleteRef?: boolean;
  copyRef?: boolean;
  copyRefByMyPost?: boolean;
  oldDataFolder?: PostFolder;
  copy?: boolean;
}

const useUploadPost = () => {
  const auth = useContext(AuthContext);
  const mutationPost = useMutationSetPost();
  const { presentToast } = useToast();
  const mutationDeletePost = useMutationDeletePost();
  const mutationEditPost = useMutationEditPost();

  const setUploadPost = useCallback(
    async (uploadPost: UploadPostProps[]) => {
      if (uploadPost) {
        for await (const val of uploadPost) {
          // presentToast('Upload post', 'success');
          /* setToast({
            summary: 'Success:',
            severity: 'success',
            detail: ,
            click: null,
            content: <UploadPost post={val.post} />,
          }); */

          try {
            if (val.editPost) {
              await mutationEditPost.mutateAsync(
                {
                  post: val.post,
                  uid: auth.user.uid,
                  blob: val.photoBlob ?? new Blob(),
                  ref: val.ref ?? '',
                },
                {
                  onSuccess: () => {
                    setTimeout(() => {
                      presentToast('Post modified', 'success');
                    }, 1500);
                    if (val.deleteImg) {
                      mutationDeletePost.mutateAsync(
                        {
                          idPost: val.post.idPost ?? '',
                          tag: val.post.tag,
                          uid: auth.user.uid,
                          ref: val.originalRef ?? '',
                          urlPhoto: val.post.img ?? '',
                        },
                        {
                          onSuccess: () => {
                            setTimeout(() => {
                              presentToast('item deleted', 'success');
                            }, 1500);
                          },
                          onError: () => {
                            setTimeout(() => {
                              presentToast('Error', 'danger');
                            }, 1500);
                          },
                        }
                      );
                    }
                  },
                  onError: () => {
                    setTimeout(() => {
                      presentToast('Error', 'danger');
                    }, 1500);
                  },
                }
              );
            } else if (val.deletePost) {
              mutationDeletePost.mutateAsync(
                {
                  idPost: val.post.idPost ?? '',
                  tag: val.post.tag,
                  uid: auth.user.uid,
                  ref: val.ref ?? '',
                  urlPhoto: val.post.img ?? '',
                  deleteRef: val.deleteRef,
                },
                {
                  onSuccess: () => {
                    setTimeout(() => {
                      presentToast('item deleted', 'success');
                    }, 1500);
                  },
                  onError: () => {
                    setTimeout(() => {
                      presentToast('Error', 'danger');
                    }, 1500);
                  },
                }
              );
            } else {
              await mutationPost.mutateAsync(
                {
                  post: val.post,
                  uid: auth.user.uid,
                  ref: val.ref ?? '',
                  copyRef: val.copyRef,
                  copyRefByMyPost: val.copyRefByMyPost,
                  originalRef: val.originalRef,
                  oldDataFolder: val.oldDataFolder,
                  copy: val.copy,
                },
                {
                  onError(error) {
                    setTimeout(() => {
                      presentToast(
                        error instanceof Error ? error.message : '',
                        'danger'
                      );
                    }, 1500);
                  },
                  onSuccess() {
                    setTimeout(() => {
                      presentToast('Post Added', 'success');
                    }, 1500);
                  },
                }
              );
              setTimeout(() => {
                if (val.deleteImg) {
                  setUploadPost([
                    {
                      post: val.post,
                      ref: val.originalRef ?? '' + val.idDocument ?? '',
                      deleteRef: true,
                      deletePost: true,
                    },
                  ]);
                }
              }, 200);
            }
          } catch {
            continue;
          } /**/
        }
        if (
          auth?.userData?.settings?.ad ??
          auth?.userData?.settings?.ad === undefined
        ) {
          showInterstitial();
        }
      }
    },
    [
      auth.user.uid,
      auth?.userData?.settings?.ad,
      mutationDeletePost,
      mutationEditPost,
      mutationPost,
      presentToast,
    ]
  );

  return { setUploadPost };
};
export default useUploadPost;
