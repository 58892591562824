/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery, type UseQueryResult } from '@tanstack/react-query';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { type Customize } from '../interface/Customize';
interface getProps {
  uid: string;
}

interface setProps {
  uid: string;
  dataCustomizations: Customize;
}

const getCustomizations = async ({ uid }: getProps): Promise<Customize> => {
  const db = getFirestore();
  const profile = doc(collection(db, 'profili'), uid);
  const document = doc(collection(profile, 'impostazioni'), 'customizations');
  const res = await getDoc(document);
  return res?.data() as Customize;
};

const setCustomizations = async ({
  uid,
  dataCustomizations,
}: setProps): Promise<boolean> => {
  try {
    const db = getFirestore();
    const profile = doc(collection(db, 'profili'), uid);
    const document = doc(collection(profile, 'impostazioni'), 'customizations');
    await setDoc(document, dataCustomizations);
    return true;
  } catch {
    return false;
  }
};

export const queryGetCustomizations = (
  props: getProps
): UseQueryResult<Customize | undefined> => {
  return useQuery(['queryGetCustomizations', props.uid], async () => {
    return await getCustomizations(props);
  });
};

export const mutationCustomizations = () => {
  return useMutation(async (props: setProps) => {
    setCustomizations(props);
  });
};
