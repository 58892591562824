import { useHistory } from 'react-router-dom';
import { memo, useCallback, useEffect } from 'react';
import { useFriendsAuth } from '../hooks/useFriendsAuth';
import { searchFriendsAuth } from '../api/Friends';
import useTvdb from '../hooks/useTvdb';
import useToast from '../hooks/useToast';
import { queryGetNotificationsToRead } from '../api/notificationsApi';
import { queryGetCustomizations } from '../api/customizeApi';
import { changeTheme, isWeb } from '../utility';
import { getUserDataProfileTab } from '../hooks/useUserData';
import { useLanguageState } from '../hooks/useLanguage';
import { IonIcon } from '@ionic/react';
import { home } from 'ionicons/icons';
import { useAuthState } from '../hooks/useAuth';
import type Notification from '../interface/Notification';
import useNotifications from '../hooks/useNotifications';
import useFirebase from '../hooks/useFirebase';

export const ProfileTab: React.FC = memo(() => {
  const [auth, setAuth] = useAuthState();
  const { requestTokenForMessaging } = useFirebase();
  const { presentToast } = useToast();
  const [, setFriendsAuth] = useFriendsAuth();
  const { data: friendsData } = searchFriendsAuth(auth?.user.uid);
  const { data } = queryGetNotificationsToRead({ uid: auth?.user.uid ?? '' });
  const [language, setLanguage] = useLanguageState();
  const { data: userData } = getUserDataProfileTab(auth?.user.uid);
  const { registerNotifications } = useNotifications();
  const { data: settings } = queryGetCustomizations({
    uid: auth.user.uid,
  });
  const history = useHistory();

  const getDetailMessage = useCallback(
    (val: Notification) => {
      try {
        switch (val?.type) {
          case 'message':
            return val.nickname + language.notificaDa;
          case 'post':
            return val.nickname + language.notificaMiPace + val.titlePost;
          default:
            return val.nickname + language.notificaCommento + val.titlePost;
        }
      } catch {
        return 'error';
      }
    },
    [language.notificaCommento, language.notificaDa, language.notificaMiPace]
  );

  const setLanguageHandle = useCallback(async () => {
    if (userData?.info?.lingua && userData?.dati.uid === auth.user.uid) {
      let correctLang: string = userData.info?.lingua ?? 'en';
      if (userData.info.lingua.length > 2) {
        correctLang = correctLang.substring(0, 2);
      }
      const lang = await import(`../store/language/${correctLang}`);
      if (lang.default) setLanguage(lang.default);
    }
  }, [auth.user.uid, setLanguage, userData]);

  useEffect(() => {
    if (auth.authenticated && friendsData) {
      setFriendsAuth(friendsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendsData]);

  useEffect(() => {
    if (data) {
      for (const val of data) {
        presentToast(getDetailMessage(val), 'primary', [
          {
            text: language.notifiche,
            role: 'info',
            handler() {
              history.push('/notifications');
            },
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (userData) {
      setAuth({ ...auth, userData });
      if (isWeb) {
        requestTokenForMessaging(userData);
      }
      if (!isWeb) {
        registerNotifications(userData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (settings) {
      changeTheme(settings.theme === 'dark-blue');
      if (settings.colorNav) {
        document.documentElement.style.setProperty(
          '--colorNav',
          '#' + settings.colorNav?.toString()
        );
      }
      if (settings.backgroundNav) {
        document.documentElement.style.setProperty(
          '--backgroundNav',
          `linear-gradient(100deg, #${settings.backgroundNav[0]?.toString()}, #${settings.backgroundNav[1]?.toString()})`
        );
      }
      if (settings.colorMenu) {
        document.documentElement.style.setProperty(
          '--colorMenu',
          '#' + settings?.colorMenu.toString()
        );
      }
      if (settings.backgroundMenu) {
        document.documentElement.style.setProperty(
          '--backgroundMenu',
          `linear-gradient(100deg, #${settings.backgroundMenu[0]?.toString()}, #${settings.backgroundMenu[1]?.toString()})`
        );
      }
    }
  }, [settings]);

  useEffect(() => {
    setLanguageHandle();
  }, [userData, setLanguageHandle]);

  useEffect(useTvdb, []);

  return <IonIcon icon={home} className="m-0" />;
});
ProfileTab.displayName = 'ProfileTab';
