/* eslint-disable react-hooks/rules-of-hooks */
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import type Notification from '../interface/Notification';

interface PropsNotifications {
  uid: string;
}

interface PropsSetNotifications {
  uid: string;
  notification: Notification;
}

const getNotifications = async ({
  uid,
}: PropsNotifications): Promise<Notification[]> => {
  const db = getFirestore();
  const col = collection(doc(db, 'profili', uid), 'notifications');
  const q = query(col, orderBy('data', 'desc'));

  const res = await getDocs(q);
  return res.docs.map((x) => x.data() as Notification);
};

const getNotificationsToRead = async ({
  uid,
}: PropsNotifications): Promise<Notification[]> => {
  const db = getFirestore();
  const col = collection(doc(db, 'profili', uid), 'notifications');
  const q = query(col, orderBy('data', 'desc'), where('read', '==', false));

  const res = await getDocs(q);
  return res.docs.map((x) => {
    setNotificationRead({ uid, notification: x.data() as Notification });
    return x.data() as Notification;
  });
};

export const setNotification = async ({
  uid,
  notification,
}: PropsSetNotifications) => {
  const db = getFirestore();
  const col = collection(doc(db, 'profili', uid), 'notifications');
  const res = await addDoc(col, { ...notification, token: '' });
  updateDoc(doc(col, res.id), { idNotification: res.id });
  const docUser = doc(db, 'profili', notification.uid);
  const docUserAuth = doc(db, 'profili', uid);
  const resUser = await getDoc(docUser);
  if (resUser.exists()) {
    updateDoc(doc(col, res.id), { nickname: resUser.data().dati.nickname });
  }
  if (notification.type === 'comments' || notification.type === 'post') {
    if (notification.typePost && notification.idPost) {
      const docPost = doc(
        docUserAuth,
        notification.typePost,
        notification.idPost
      );
      const resPost = await getDoc(docPost);

      if (resPost.exists()) {
        updateDoc(doc(col, res.id), { titlePost: resPost.data().titolo });
      }
    }
  }

  const recipientToken = await getDoc(doc(collection(db, 'profili'), uid));
  if (recipientToken.exists() && recipientToken?.data()?.settings?.token) {
    sendNotification(
      recipientToken?.data().settings.token ?? '',
      notification?.message ?? '',
      notification?.titlePost ??
        `New notification from ${
          resUser?.data()?.dati?.nickname ?? 'a Biblion user'
        }`
    );
  }
};

const setNotificationRead = async ({
  uid,
  notification,
}: PropsSetNotifications) => {
  const db = getFirestore();
  const col = collection(doc(db, 'profili', uid), 'notifications');
  updateDoc(doc(col, notification.idNotification), { read: true });
};

const deleteNotifications = async ({ uid }: PropsNotifications) => {
  const db = getFirestore();
  const col = collection(doc(db, 'profili', uid), 'notifications');
  const res = await getDocs(col);
  res.docs.forEach((val) => {
    deleteDoc(doc(col, val.data().idNotification));
  });
};

export const queryGetNotifications = (
  props: PropsNotifications
): UseQueryResult<Notification[]> => {
  return useQuery(['getNotifications', props.uid], async () => {
    return await getNotifications(props);
  });
};

export const queryGetNotificationsToRead = (
  props: PropsNotifications
): UseQueryResult<Notification[]> => {
  return useQuery(['getNotificationsToRead', props.uid], async () => {
    return await getNotificationsToRead(props);
  });
};

export const useMutationSetNotification = () => {
  return useMutation(async (props: PropsSetNotifications) => {
    try {
      await setNotification(props);
    } catch (error) {
      throw new Error(error as string);
    }
  });
};

export const useMutationSetNotificationRead = () => {
  return useMutation(async (props: PropsSetNotifications) => {
    try {
      await setNotificationRead(props);
    } catch (error) {
      throw new Error(error as string);
    }
  });
};

export const useMutationDeleteNotifications = () => {
  return useMutation(async (props: PropsNotifications) => {
    try {
      await deleteNotifications(props);
    } catch (error) {
      throw new Error(error as string);
    }
  });
};

export const sendNotification = (
  recipientToken: string,
  message: string,
  title: string
) => {
  const payload = {
    notification: {
      //   click_action: 'FCM_PLUGIN_ACTIVITY',
      title,
      body: message,
    },
    to: recipientToken,
  };

  fetch('https://fcm.googleapis.com/fcm/send', {
    method: 'POST',
    headers: {
      Authorization:
        'key=AAAAGObFqtk:APA91bGEqmxFjm_Agpeszby87V2HikkZJuYTsrRBUIEOBwTRqA8UMDtkD3XHYsQswdHSqdrieT_iFJ9FJ-J7BvgpM0GZn1yf75nxFh956uAwYP4F2IeKp6vZgdwKRPGkEs0tRseg-bDk',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (response.status === 200) {
        //  console.log('Notifica inviata con successo', response);
      } else {
        //    console.error("Errore nell'invio della notifica");
      }
    })
    .catch(() => {
      //  console.error("Errore nell'invio della notifica:", error);
    });
};

interface PropsSetTokenToDatabase {
  token?: string;
  /* tokenAndroid?: string;
  tokenIos?: string; */
  uid: string;
}
const setTokenToDatabase = async ({
  token,
  uid,
}: /* tokenAndroid,
  tokenIos, */
PropsSetTokenToDatabase) => {
  const db = getFirestore();
  const document = doc(db, 'profili', uid);
  /* if (token) {
    updateDoc(document, { 'settings.token': token });
  } else if (tokenAndroid) {
    updateDoc(document, { 'settings.tokenAndroid': tokenAndroid });
  } else if (tokenIos) {
    updateDoc(document, { 'settings.tokenIos': tokenIos });
  } */
  updateDoc(document, { 'settings.token': token });
};

export const useTokenToDatabase = (uid: string) => {
  const queryClient = useQueryClient();
  return useMutation(setTokenToDatabase, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userData', uid] });
      queryClient.invalidateQueries({ queryKey: ['userDataProfileTab', uid] });
    },
  });
};
