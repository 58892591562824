import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';

export const messageSuccess = (message: string) => {
 /* const db = getFirestore();
  const col = collection(db, 'clarity');
  addDoc(col, {
    message,
    date: serverTimestamp(),
    type: 'success',
  }); */
};

export const messageError = (message: string) => {
  const db = getFirestore();
  const col = collection(db, 'clarity');
  addDoc(col, {
    message,
    date: serverTimestamp(),
    type: 'error',
  });
};
