import { createContext } from 'react';
import type AuthData from '../interface/AuthData';
import type RootFolder from '../interface/Folder';
import type Language from '../interface/Language';
import eng from './language/en';

export const RootFolderContext = createContext<RootFolder | undefined>(
  undefined
);
export const LanguageContext = createContext<Language>(eng);

export const AuthContext = createContext<AuthData>({
  user: {
    uid: '',
    email: '',
    emailVerified: false,
  },
  authenticated: false,
});
