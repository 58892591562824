import type { FriendsDetail } from './interface/Friends';
import {
  AdMob,
  type BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
  BannerAdPluginEvents,
  type AdMobBannerSize,
  type AdLoadInfo,
  InterstitialAdPluginEvents,
  type AdOptions,
  type RewardAdOptions,
  RewardAdPluginEvents,
  type AdMobRewardItem,
} from '@capacitor-community/admob';
import { isPlatform } from '@ionic/core';

export const createMarkup = (str: string) => {
  return { __html: str };
};

export const isWeb =
  (isPlatform('desktop') && !isPlatform('ios') && !isPlatform('android')) ||
  isPlatform('mobileweb');
// getPlatforms().includes('desktop') || getPlatforms().includes('mobileweb');

export const isIosDevice = () => {
  return isPlatform('ios') && !isWeb;
};

export const changeTheme = (darkTheme: boolean) => {
  const themeLink = document.getElementById('theme-link');
  if (themeLink) {
    themeLink.setAttribute(
      'href',
      `./themes/${darkTheme ? 'dark-blue' : 'light-blue'}.css`
    );
    document.body.classList.toggle('dark', darkTheme);
  }
};
export const setContentSecurityPolicy = () => {
  if (isIosDevice()) {
    const themeLink = document.getElementById('Content-Security-Policy');
    if (themeLink) {
      themeLink.setAttribute('http-equiv', 'Content-Security-Policy');
      themeLink.setAttribute(
        'content',
        "default-src * data: blob: ws: wss: gap://ready file://*; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline'; media-src *; img-src * 'self' data: blob:;"
      );
    }
  }
};

export const searchFollowing = (uid: string, friendsUsed: FriendsDetail[]) => {
  if (!friendsUsed) {
    return false;
  }
  return !!friendsUsed.find((x) => x.uid === uid);
};

const profileUid: string[] = [];

export const adjustSlideHeight = (uid: string) => {
  if (!profileUid.includes(uid)) {
    profileUid.push(uid);
    const containers: any = document.getElementsByClassName('slide-container');
    let maxHeight = 0;

    // Find the tallest container height
    for (let i = 0; i < containers.length; i++) {
      const containerHeight = containers[i].offsetHeight;
      if (containerHeight > maxHeight) {
        maxHeight = containerHeight;
      }
    }

    // Set the same height for all containers
    for (let i = 0; i < containers.length; i++) {
      containers[i].style.height = `${maxHeight}px`;
    }
  }
};

export async function initializeAdMob(): Promise<void> {
  // eslint-disable-next-line no-constant-condition
  if (false) { // !isPlatform('desktop')
    const { status } = await AdMob.trackingAuthorizationStatus();

    if (status === 'notDetermined') {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/
    }

    AdMob.initialize({
      // testingDevices: ["2077ef9a63d2b398840261c8221a0c9b"],
      //  initializeForTesting: true,
      // requestTrackingAuthorization: true,
    });
  }
  // interstitial();
}

export async function banner(): Promise<void> {
  AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
    // Subscribe Banner Event Listener
  });

  AdMob.addListener(
    BannerAdPluginEvents.SizeChanged,
    (size: AdMobBannerSize) => {
      // Subscribe Change Banner Size
    }
  );
  const adId = isPlatform('ios')
    ? 'ca-app-pub-9665434469079999/3893470135'
    : 'ca-app-pub-9665434469079999/2487361764';

  const options: BannerAdOptions = {
    adId,
    adSize: BannerAdSize.BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    margin: 0,
    isTesting: false,
    // npa: true
  };
  AdMob.showBanner(options);
}

export async function showInterstitial(): Promise<void> {
  // eslint-disable-next-line no-constant-condition
  if (false) { // isPlatform('desktop')
    AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared interstitial
    });

    const adId = isPlatform('ios')
      ? 'ca-app-pub-9665434469079999/3702354016'
      : 'ca-app-pub-9665434469079999/5415959192';
    const options: AdOptions = {
      adId,
      // isTesting: true,
      // npa: true
    };
    await AdMob.prepareInterstitial(options);
    AdMob.showInterstitial();
  }
}

export async function rewardVideo(): Promise<void> {
  AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
    // Subscribe prepared rewardVideo
  });

  AdMob.addListener(
    RewardAdPluginEvents.Rewarded,
    (rewardItem: AdMobRewardItem) => {
      // Subscribe user rewarded
    }
  );

  const adId = isPlatform('ios')
    ? 'ca-app-pub-9665434469079999/3318755061'
    : 'ca-app-pub-9665434469079999/8267857662';
  const options: RewardAdOptions = {
    adId,
    // isTesting: true
    // npa: true
    // ssv: {
    //   userId: "A user ID to send to your SSV"
    //   customData: JSON.stringify({ ...MyCustomData })
    // }
  };
  await AdMob.prepareRewardVideoAd(options);
}
