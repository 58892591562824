import { atom } from 'recoil';
import type Friends from '../interface/Friends';
import { loadData } from './storage';

const atomFriendsAuth = atom<Friends>({
  key: 'atomFriendsAuth',
  default: {
    followers: [],
    following: [],
    reload: 1,
  },
  effects: [loadData('friendsAuth')],
});

export const atomFriendsUser = atom<Friends>({
  key: 'atomFriendsUser',
  default: {
    followers: [],
    following: [],
  },
  effects: [loadData('atomFriendsUser')],
});
export default atomFriendsAuth;
