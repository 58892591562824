/* eslint-disable no-console */
import { useRecoilState, useRecoilValue } from 'recoil';
import atomNotifications from '../store/atomNotifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { useCallback } from 'react';
import authStore from '../store/auth/authStore';
import { useTokenToDatabase } from '../api/notificationsApi';
import type UserData from '../interface/UserData';
export const useNotificationsValue = () => {
  return useRecoilValue(atomNotifications);
};

export const useNotificationsState = () => {
  return useRecoilState(atomNotifications);
};

const useNotifications = () => {
  const user = useRecoilValue(authStore);
  const { mutate: setTokenToDatabase } = useTokenToDatabase(user?.user?.uid);
  const addListeners = useCallback(async (userData: UserData) => {
    try {
      await PushNotifications.addListener('registration', (currentToken) => {
        // console.info('Registration token: ', token.value);
        if (
          currentToken?.value &&
          userData?.settings?.token !== currentToken?.value
        ) {
          // Send the token to your server and update the UI if necessary
          setTokenToDatabase({
            token: currentToken?.value,
            uid: userData?.dati?.uid,
          });
        }
      });

      await PushNotifications.addListener('registrationError', () => {
        // console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          //   console.log('Push notification received: ', notification);
        }
      );

      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          /*  console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        ); */
        }
      );
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Error');
    }
  }, [setTokenToDatabase]);

  const registerNotifications = useCallback(async (userData: UserData) => {
    try {
      await addListeners(userData);
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }
      await PushNotifications.register();
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Error');
    }
    // requestToken();
  }, [addListeners]);

  const getDeliveredNotifications = useCallback(async () => {
    /*  const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList); */
  }, []);

  return { registerNotifications, getDeliveredNotifications };
};

export default useNotifications;
