import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import authStore from '../store/auth/authStore';
import authentication, {
  appleSigIn,
  googleSigIn,
} from '../api/authentications';

/**/
interface LoginRequest {
  email: string;
  password: string;
}

export const useLogin = () =>
  useMutation(
    async ({ email, password }: LoginRequest) =>
      await authentication(email, password)
  );
export const useAuthValue = () => {
  return useRecoilValue(authStore);
};

export const useAuthState = () => {
  return useRecoilState(authStore);
};

export const useGoogleSignIn = () => {
  googleSigIn();
};

export const useAppleSignIn = () => {
  appleSigIn();
};
/*
export const useAuthentication = (email: string, password: string) => {
  authentication(email, password);
};
*/
