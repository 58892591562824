/* eslint-disable react-hooks/rules-of-hooks */
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import {
  getFirestore,
  getDoc,
  collection,
  doc,
  updateDoc,
  getDocs,
  addDoc,
  query,
  orderBy,
  limit,
  deleteDoc,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import axios from 'axios';
import type Post from '../interface/Post';
import { uploadImgDatabasePost, uploadImgPost } from './uploadImgApi';
import { type PostFolder, type Tag } from '../interface/Folder';
import { deleteObject, getStorage, ref as refStorage } from 'firebase/storage';

interface GetPostByTagProps {
  tag: Tag;
  uid: string;
  // numData: number;
}

interface SetPostProps {
  post: Post;
  oldDataFolder?: PostFolder;
  ref: string;
  uid: string;
  copyRef?: boolean;
  copyRefByMyPost?: boolean;
  originalRef?: string;
  copy?: boolean;
  move?: boolean;
}

interface EditPostProp {
  post: Post;
  uid: string;
  blob: Blob;
  ref?: string;
}

interface PropRemovePost {
  uid: string;
  ref: string;
  idPost: string;
  tag: Tag;
  urlPhoto: string;
  deleteRef?: boolean;
  deletePostByTag?: boolean;
  move?: boolean;
}

interface SetPostIntoDatabaseProps {
  post: Post;
  uid: string;
  invalidateQuery: (uid: string) => void;
}

const getPost = async (
  idPost: string,
  tag: Tag,
  uid: string
): Promise<Post> => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  const q = doc(document, tag, idPost);
  const res = await getDoc(q);
  const data = res.data() as Post;
  return { ...data, idPost: res.id, tag };
};

const getPostByTag = async ({
  tag,
  uid,
}: GetPostByTagProps): Promise<Post[]> => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  const q = query(collection(document, tag), orderBy('dataPost', 'desc'));
  /* if (numData) {
    q = query(
      collection(document, tag),
      orderBy('dataPost', 'desc'),
      limit(numData)
    );
  } */
  const res = await getDocs(q);
  const data = res.docs.map((post) => {
    return { ...(post.data() as Post), idPost: post.id };
  });
  return data;
}; /*
const getNumberPostForCharts = async ({
  uid
}: Omit<GetPostByTagProps, 'tag'>): Promise<Post[]> => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  const book = query(collection(document, 'libri'), orderBy('dataPost', 'desc'));
  const book = query(collection(document, 'libri'), orderBy('dataPost', 'desc'));
  const book = query(collection(document, 'libri'), orderBy('dataPost', 'desc'));
  const book = query(collection(document, 'libri'), orderBy('dataPost', 'desc'));
  //'film' | 'serietv' | 'libri' | 'videogiochi'

  const res = await getDocs(q);
  const data = res.docs.map((post) => {
    return { ...(post.data() as Post), idPost: post.id };
  });
  return data;
}; */
const setPost = async ({
  post,
  oldDataFolder,
  ref,
  uid,
  copyRef,
  copyRefByMyPost,
  originalRef,
  copy,
  invalidateQuery,
}: SetPostProps & { invalidateQuery: (uid: string) => void }): Promise<Post | Error> => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  const path = !ref ? 'folders' : 'folders/' + ref;
  const q = query(collection(document, path), orderBy('id', 'desc'), limit(1));
  const id = await getDocs(q);
  if (!copyRef && !copyRefByMyPost) {
    if (copy !== undefined && !copy) {
      const resFolder = await addDoc(collection(document, path), {
        ...(oldDataFolder ?? {}),
        id: !id.empty ? id.docs[0].data().id + 1 : 0,
        type: post.tag,
        ref,
      });
      if (oldDataFolder?.idPost) {
        const oldPost = await getDoc(
          doc(document, post.tag, oldDataFolder.idPost)
        );
        if (oldPost.exists()) {
          const oldRefFolder = oldPost.data().refFolder ?? [];
          updateDoc(doc(document, post.tag, oldPost.id), {
            refFolder: [...oldRefFolder, path + '/' + resFolder.id],
          });
        }
      }
      invalidateQuery(uid);
    } else {
      const resPost = await addDoc(collection(document, post.tag), {
        titolo: post.titolo,
        autore: post.autore ?? '',
        categoria: post.categoria ?? [],
        dataPost: serverTimestamp(),
        descrizione: post.descrizione ?? '',
        preferito: post.preferito ?? false,
        tag: post.tag,
        trailer: post.trailer ?? '',
        img: '',
        ...(post?.idTvdb ? { idTvdb: post.idTvdb ?? '' } : {}),
      });
      const resFolder = await addDoc(collection(document, path), {
        ...(oldDataFolder ?? {}),
        id: !id.empty ? id.docs[0].data().id + 1 : 0,
        type: post.tag,
        idPost: resPost.id,
        ref,
      });
      const blob = await getImg(post.img);
      if (blob.size !== 0) uploadImgPost(blob, uid, post.tag, resPost.id);
      const oldPost = await getDoc(doc(document, post.tag, resPost.id));
      if (oldPost.exists()) {
        const oldRefFolder = oldPost.data().refFolder ?? [];
        updateDoc(doc(document, post.tag, resPost.id), {
          refFolder: [...oldRefFolder, path + '/' + resFolder.id],
        });
      }

      await addDoc(collection(db, 'postGlobali'), {
        data: serverTimestamp(),
        pathPost: `profili/${uid}/${post.tag}/${resPost.id}`,
        pathUser: `profili/${uid}`,
        tag: post.tag,
        uid,
        idDocumentPost: resPost.id,
      });

      setPostIntoDatabase({ uid, post, invalidateQuery });
    }
  } else {
    const res = await addDoc(collection(document, path), {
      id: !id.empty ? id.docs[0].data().id + 1 : 0,
      type: post.tag,
      idPost: post.idPost,
      ref,
      link: !copyRefByMyPost,
    });
    if (copyRefByMyPost) {
      if (post.idPost) {
        const oldRefFolder = post.refFolder ?? [];
        updateDoc(doc(document, post.tag, post.idPost), {
          refFolder: [...oldRefFolder, path + '/' + res.id],
        });
      }
    } else {
      const pathLinkedPost = !originalRef
        ? 'folders'
        : 'folders/' + originalRef;
      const linkedDoc = await getDoc(doc(document, pathLinkedPost));

      updateDoc(doc(document, pathLinkedPost), {
        linkedPosts:
          linkedDoc.exists() && linkedDoc.data().linkedPosts
            ? [
                ...linkedDoc.data().linkedPosts,
                'folders/' + (ref ? ref + '/' + res.id : res.id),
              ]
            : ['folders/' + (ref ? ref + '/' + res.id : res.id)],
      });
    }
  }
  return post;
};

const editPost = async ({
  post,
  uid,
  blob,
  ref,
  invalidateQuery,
}: EditPostProp & { invalidateQuery: (uid: string) => void }) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  if (post?.idPost) {
    updateDoc(doc(document, post.tag, post.idPost), { ...post });
    if (blob.size !== 0) uploadImgPost(blob, uid, post.tag, post.idPost);
    setPostIntoDatabase({ uid, post, invalidateQuery });
  } else {
    const path = !ref ? 'folders' : 'folders/' + ref;
    const q = query(
      collection(document, path),
      orderBy('id', 'desc'),
      limit(1)
    );
    const id = await getDocs(q);
    const resPost = await addDoc(collection(document, post.tag), {
      ...post,
      titolo: post.titolo,
      autore: post.autore ?? '',
      categoria: post.categoria ?? [],
      dataPost: serverTimestamp(),
      descrizione: post.descrizione ?? '',
      preferito: post.preferito ?? false,
      tag: post.tag,
      trailer: post.trailer ?? '',
      img: '',
    });
    addDoc(collection(document, path), {
      id: !id.empty ? id.docs[0].data().id + 1 : 0,
      type: post.tag,
      idPost: resPost.id,
      ref,
    });
    if (blob.size !== 0) uploadImgPost(blob, uid, post.tag, resPost.id);
    await addDoc(collection(db, 'postGlobali'), {
      data: serverTimestamp(),
      pathPost: `profili/${uid}/${post.tag}/${resPost.id}`,
      pathUser: `profili/${uid}`,
      tag: post.tag,
      uid,
      idDocumentPost: resPost.id,
    });

    setPostIntoDatabase({ uid, post, invalidateQuery });
  }
};
// const key = "AIzaSyCuaxTP8SR-ntm_1De1itA2NFAFxKWv_Vk";
const deletePost = async ({
  uid,
  ref,
  idPost,
  tag,
  urlPhoto,
  deleteRef,
}: PropRemovePost) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  if (ref) {
    const path = 'folders/' + ref;
    const oldPostInFolder = await getDoc(doc(document, path));
    if (oldPostInFolder.exists()) {
      deleteRefPostInFolder(uid, oldPostInFolder.data().linkedPosts);
    }
    deleteDoc(doc(document, path));
  }
  if (!deleteRef) {
    const oldPost = await getDoc(doc(document, tag, idPost));
    if (oldPost.exists()) {
      deleteRefPostInFolder(uid, oldPost.data().refFolder);
    }
    if (urlPhoto) {
      const storage = getStorage();
      const refImg = refStorage(storage, urlPhoto);
      deleteObject(refImg);
    }
    if (tag !== 'folder') {
      deleteDoc(doc(document, tag, idPost.toString()));
    }

    const colGlobalPost = collection(db, 'postGlobali');
    const q = query(
      colGlobalPost,
      where('idDocumentPost', '==', idPost.toString())
    );
    const res = await getDocs(q);
    if (!res.empty) {
      deleteDoc(doc(colGlobalPost, res.docs[0].id));
    }
  }
};

interface EpisodesViewedProps {
  uid: string;
  idPost: string;
  episodesViewed: Array<{
    id: number;
    season: number;
  }>;
}

const setEpisodesViewed = async ({
  idPost,
  uid,
  episodesViewed,
}: EpisodesViewedProps) => {
  try {
    const db = getFirestore();
    const document = doc(collection(db, 'profili'), uid);
    const col = collection(document, 'serietv');
    // const q = query(col, where('idPost', '==', idPost));
    await updateDoc(doc(col, idPost), { episodesViewed });
    return true;
  } catch {
    return false;
  }
};

const deleteRefPostInFolder = async (uid: string, refFolder?: string[]) => {
  if (refFolder) {
    const db = getFirestore();
    const document = doc(collection(db, 'profili'), uid);
    for await (const path of refFolder) {
      const postInFolder = await getDoc(doc(document, path));
      if (postInFolder.exists() && postInFolder.data().linkedPosts) {
        const linkedPosts = postInFolder.data().linkedPosts;
        deleteRefPostInFolder(uid, linkedPosts);
      }
      deleteDoc(doc(document, path));
    }
  }
};

export const getImg = async (url: string) => {
  return await axios
    .get(
      `https://us-central1-appbook-bf2d3.cloudfunctions.net/getImg?url=${encodeURIComponent(
        url
      )}`
    )
    .then(async (val) => {
      const img = new Blob([new Uint8Array(val.data.img.data)], {
        type: 'image/png',
      });
      return img;
    });
};

const setPostIntoDatabase = async ({
  uid,
  post,
  invalidateQuery,
}: SetPostIntoDatabaseProps) => {
  const db = getFirestore();
  const document = doc(collection(db, post.tag), post.titolo);

  const res = await getDoc(document);
  if (res.exists()) {
    const oldPost: Post = res.data() as Post;
    const newPost = {
      data: serverTimestamp(),
      img: oldPost.img ?? '',
      tag: post.tag,
      titolo: post.titolo,
      autore: oldPost.autore ?? post.autore ?? '',
      categoria: oldPost.categoria ?? post.categoria ?? [],
      descrizione: oldPost.descrizione ?? post.descrizione ?? '',
      trailer: oldPost.trailer ?? post.trailer ?? '',
    };
    await updateDoc(document, { ...newPost });
  } else {
    const newPost = {
      data: serverTimestamp(),
      img: post.img,
      tag: post.tag,
      titolo: post.titolo,
      autore: post.autore ?? '',
      categoria: post.categoria ?? [],
      descrizione: post.descrizione ?? '',
      trailer: post.trailer ?? '',
    };
    await setDoc(document, { ...newPost });
  }
  if (post.img) {
    const blob = await getImg(post.img);
    if (blob.size !== 0) {
      uploadImgDatabasePost(blob, post.titolo, post.tag, uid, invalidateQuery);
    }
  } else {
    invalidateQuery(uid);
  }
  setDoc(doc(document, 'user', uid), { uid, data: serverTimestamp() });
  if (post.valutazione) {
    setDoc(doc(document, 'ratings', uid), {
      uid,
      data: serverTimestamp(),
      rating: post.valutazione,
    });
  }
};

export const queryPost = (
  idPost: string,
  tag: Tag,
  uid: string,
  settings?: any
): UseQueryResult<Post> => {
  return useQuery(
    ['post', uid, tag, idPost],
    async () => {
      if (!uid) {
        throw new Error('Account not found');
      }
      if (idPost) {
        const post = await getPost(idPost, tag, uid);
        return post;
      }
    },
    {
      refetchInterval: false,
      ...settings,
    }
  );
};

export const queryPostByTag = (
  props: GetPostByTagProps
): UseQueryResult<Post[]> => {
  return useQuery(
    ['post', props.uid, props.tag],
    async () => {
      if (!props.uid) {
        throw new Error('Account not found');
      }
      return (await getPostByTag(props)) ?? [];
    },
    { refetchInterval: false, refetchOnWindowFocus: false }
  );
};

export const useMutationSetPost = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = (uid: string) => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'post' && query.queryKey[1] === uid,
    });

    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'folder' && query.queryKey[1] === uid,
    });
  };
  return useMutation(async (props: SetPostProps) => {
    try {
      setPost({ ...props, invalidateQuery });
    } catch (error) {
      throw new Error((error as Error).message);
    }
  });
};

export const useMutationEditPost = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = (uid: string) => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'post' && query.queryKey[1] === uid,
    });

    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'folder' && query.queryKey[1] === uid,
    });
  };
  return useMutation(async (props: EditPostProp) => {
    try {
      editPost({ ...props, invalidateQuery });
    } catch (error) {
      throw new Error('Error to update post');
    }
  });
};

export const useMutationDeletePost = () => {
  const queryClient = useQueryClient();
  return useMutation(async (props: PropRemovePost) => {
    deletePost(props).then(() => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'post' && query.queryKey[1] === props.uid,
      });

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'folder' && query.queryKey[1] === props.uid,
      });
    });
    return true;
  });
};

export const useMutationSetEpisodesViewed = () =>
  useMutation(setEpisodesViewed);

/*
const setNewDatabase = async () => {
  const db = getFirestore();
  const profili = await getDocs(collection(db, 'profili'));
  for await (const res of profili.docs) {
    const uid = res.data().dati.uid;
    const libri = (
      await getDocs(collection(db, 'profili', uid, 'libri'))
    ).docs.map((x) => x.data());
  }
}; */
/* console.log("setNewdata")
  for (const res of film.docs) {
    const docId = await addDoc(
      collection(document, "folders/UPlLi3ywEiNt0UERHQtg/folder"),
      {
        idPost: res.id,
        id: res.data().id ?? 0,
        type: "film",
      }
    );
    updateDoc(doc(document, "folders/UPlLi3ywEiNt0UERHQtg/folder", docId.id), {
      ref: docId.id,
      path: docId.id + "/",
    });
  } */
// setDoc(doc(document,"folders/root"),{ ref:"/root"})
// updateDoc(document,{folders:cartellaRoot})
