import { atom } from 'recoil';
import type Language from '../../interface/Language';
import en from './en';
import { loadData } from '../storage';

const atomLanguage = atom<Language>({
  key: 'atomLanguage',
  default: en,
  effects: [loadData('language')],
});

export default atomLanguage;
