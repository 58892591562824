/* eslint-disable react-hooks/rules-of-hooks */
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import {
  getFirestore,
  getDoc,
  collection,
  doc,
  setDoc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  limit,
} from 'firebase/firestore';
import { type FriendsDetail } from '../interface/Friends';
import type Friends from '../interface/Friends';

interface followrequest {
  uid: string;
  uidAuth: string;
  follow: boolean;
}

const getFriends = async (uid: string): Promise<Friends> => {
  try {
    const db = getFirestore();
    const document = doc(collection(db, 'profili'), uid);
    const followers = await getDocs(collection(document, 'followers'));
    const following = await getDocs(collection(document, 'following'));
    const dataFollowers: FriendsDetail[] = [];
    for await (const val of followers.docs) {
      if (val.data().uid ?? false) {
        const document = doc(collection(db, 'profili'), val.data().uid);
        const data = await getDoc(document);
         dataFollowers.push({
          uid: val.data().uid,
          avatar: data.data()?.dati.foto ?? '',
          nickname: data.data()?.dati.nickname ?? '',
          name: data.data()?.dati.nome ?? '',
        });
      }
    }
    const dataFollowing: FriendsDetail[] = [];
    for await (const val of following.docs) {
      if (val.data().uid ?? false) {
        const document = doc(collection(db, 'profili'), val.data().uid);
        const data = await getDoc(document);
         dataFollowing.push({
          uid: val.data().uid,
          avatar: data.data()?.dati.foto ?? '',
          nickname: data.data()?.dati.nickname ?? '',
          name: data.data()?.dati.nome ?? '',
        });
      }
    }
    return {
      followers: dataFollowers,
      following: dataFollowing,
    };
  } catch {
    return {
      followers: undefined,
      following: undefined,
    };
  }
};

const postFriends = async (uid: string, uidAuth: string, follow: boolean) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uidAuth);
  const document2 = doc(collection(db, 'profili'), uid);
  if (follow) {
    deleteDoc(doc(document, 'following', uid));
    deleteDoc(doc(document2, 'followers', uidAuth));
  } else {
    await setDoc(doc(document, 'following', uid), {
      uid,
    });
    await setDoc(doc(document2, 'followers', uidAuth), {
      uid: uidAuth,
    });
  }
  return true;
  // return await getFriends(uid);
};

export const searchFriends = (uid: string): UseQueryResult<Friends> => {
  return useQuery(
    ['searchFriends', uid],
    async () => {
      if (!uid) {
        throw new Error('Friend search failed');
      }
      return await getFriends(uid);
    },
    { enabled: true, refetchInterval: false }
  );
};

export const useMutationFriends = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ uid, uidAuth, follow }: followrequest) => {
      return await postFriends(uid, uidAuth, follow);
    },
    {
      onSuccess: (data: boolean, variables: followrequest) => {
        queryClient.invalidateQueries({
          queryKey: ['searchFriends', variables.uidAuth],
        });
        queryClient.invalidateQueries({
          queryKey: ['searchFriends', variables.uid],
        });
      },
    }
  );
};

const getFriendsRaccommended = async (
  num: number
): Promise<FriendsDetail[]> => {
  const db = collection(getFirestore(), 'profili');
  const q = query(db, orderBy('info.data', 'desc'), limit(num));
  const pers = await getDocs(q);
  return pers.docs.map((x) => {
    const val = x.data();
    return {
      uid: val.dati?.uid,
      avatar: val.dati.foto ?? '',
      nickname: val.dati.nickname ?? '',
      name: val.dati.nome ?? '',
    };
  });
};

export const useFriendsRaccommended = (num: number): UseQueryResult => {
  return useQuery(
    ['queryFriendsRaccommended', num],
    async () => {
      return await getFriendsRaccommended(num);
    },
    { refetchInterval: false }
  );
};

export const searchFriendsAuth = (uidAuth: string) => {
  return searchFriends(uidAuth);
};
