import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithCredential,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  OAuthProvider,
  deleteUser,
  signInWithPopup,
} from 'firebase/auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Plugins } from '@capacitor/core';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import { getPlatforms } from '@ionic/react';
import { useMutation } from '@tanstack/react-query';
import { isWeb } from '../utility';

interface RegisterProps {
  email: string;
  password: string;
  nickname: string;
  name: string;
  language: string;
}

const authentication = async (email: string, password: string) => {
  const auth = getAuth();
  return await signInWithEmailAndPassword(auth, email, password)
    .then(({ user }) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
};

export const googleSigIn = async () => {
  if (!isWeb) {
    GoogleAuth.initialize({
      clientId:
        '106950929113-v23hi154p9qbn3coi4nl40p015vng2un.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
    setTimeout(() => {
      GoogleAuth.signIn().then((googleUser) => {
        const credential = GoogleAuthProvider.credential(
          googleUser.authentication.idToken
        );
        const auth = getAuth();

        signInWithCredential(auth, credential);
      });
    }, 1000);
  } else {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        if (result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          if (credential) signInWithCredential(auth, credential);
        }
      })
      .catch(() => {
        // Handle Errors here.
        // console.log(error);
        // ...
      });
  }
};

export const appleSigIn = () => {
  const { SignInWithApple } = Plugins;
  SignInWithApple.Authorize().then(async (res: any) => {
    /*   const credential = GoogleAuthProvider.credential(
        res.response.identityToken
      ); */
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const credential = provider.credential({
      idToken: res.identityToken ?? res.response.identityToken,
    });
    const auth = getAuth();
    signInWithCredential(auth, credential);
  });
};

export const setDataUserToDatabase = async (
  uid: string,
  nickname: string,
  email: string,
  language: string,
  name: string
) => {
  const formrequest = {
    dati: {
      descrizione: '',
      nickname: nickname.trim() ?? '',
      email: email.trim(),
      nome: name.trim() ?? '',
      uid,
    },
    info: {
      data: serverTimestamp(),
      eula: true,
      lingua: language,
      platform: getPlatforms(),
    },
    link: {
      link: [''],
      facebook: '',
      instagram: '',
      twitter: '',
    },
    settings: {
      notifications: true,
      ad: true,
      token: '',
    },
  };
  await setDoc(doc(collection(getFirestore(), 'profili'), uid), formrequest);
  setDoc(
    doc(
      collection(getFirestore(), 'profili/' + uid + '/impostazioni'),
      'notifiche'
    ),
    { notifiche: 'true' }
  );
  setDoc(
    doc(
      collection(getFirestore(), 'profili/' + uid + '/impostazioni'),
      'pubblicità'
    ),
    { pubblicità: 'true' }
  );
};

const register = async ({
  email,
  nickname,
  name,
  password,
  language,
}: RegisterProps): Promise<string> => {
  const auth = getAuth();
  const controlNickname = await getDocs(
    query(
      collection(getFirestore(), 'profili'),
      where('dati.nickname', '==', nickname.trim())
    )
  );
  if (controlNickname.empty) {
    const controlEmail = await getDocs(
      query(
        collection(getFirestore(), 'profili'),
        where('dati.email', '==', email.trim())
      )
    );
    if (controlEmail.empty) {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setDataUserToDatabase(
        userCredential.user.uid,
        nickname,
        email,
        language,
        name
      );

      sendEmailForVerification();
      authentication(email, password);
      return nickname;
    }
    throw new Error('errorEmail');
  }
  throw new Error('errorNickname');
};

export const sendEmailForVerification = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) sendEmailVerification(user);
};

export const logout = () => {
  const auth = getAuth();
  signOut(auth);
};

const deleteAccount = async (uid: string) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) await deleteUser(user);
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  await deleteDoc(document);
};

export default authentication;

export const useMutationRegister = () => {
  return useMutation(async (props: RegisterProps) => {
    try {
      return await register(props);
    } catch (error) {
      if (error instanceof Error) throw new Error(error.message);
    }
  });
};

export const MutationDeleteAccount = () => {
  return useMutation(async (uid: string) => {
    try {
      await deleteAccount(uid);
    } catch (error) {
      if (error instanceof Error) throw new Error(error.message);
    }
  });
};
