const eng = {
  feedback: 'Share your ideas, report any bugs, or suggest new features to enhance your experience with the app',
  episodes: 'Episodes',
  supportBiblion: 'Support Biblion by reviewing the app',
  statistics: 'Statistics',
  collection: 'Collection',
  collectionDescription:
    'Manage your collection by creating folders and adding books, movies, TV series, and video games',
  booksDescription: 'Saved Books',
  moviesDescription: 'Saved Movies',
  seriesDescription: 'Saved TV Series',
  videoGamesDescription: 'Saved Video Games',
  testoDaImmagine: 'Text from image',
  googleCloudVisionButton: 'Image search',
  eliminaAccount: 'Delete Account',
  personalizza: 'Customize',
  aggiungiAPosts: 'Add to Posts',
  settedLanguage: 'en',
  riordina: 'Reorder',
  creaCollegamento: 'Create link',
  eliminaCollegamento: 'Delete link',
  copia: 'Copy',
  sposta: 'Move',
  aggiungi: 'Add',
  nomeCartella: 'Folder name',
  cartelle: 'Folders',
  cartella: 'Folder',
  tutti: 'All',
  rispondi: 'Reply',
  modifica: 'Edit',
  amici: 'Friends',
  consigliati: 'Recommended',
  nicknameNonDisponibile: 'Nickname not available',
  emailNonDisponibile: 'Email not available',
  descrizioneBiblion:
    'Biblion allows you to save, rate, comment and share books, TV series, movies and video games.',
  cerca: 'Search',
  profilo: 'Profile',
  lingua: 'Language',
  passDimenticata: 'Forgot password',
  creaAcount: 'Create ',
  accedi: 'Sign in',
  accediCon: 'Sign in with ',
  liste: 'List',
  rimuoviLista: 'Delete List',
  creaLista: 'Create List',
  addLista: 'Add to List',
  nomeNonDisponibile: 'Name not available',
  esci: 'Exit',
  libriLetti: 'Books read',
  modificaProfilo: 'Edit Profile',
  dettagli: 'Details',
  elenco: 'List',
  valutazione: 'Rating',
  dataInizio: 'Start Date',
  dataFine: 'End Date',
  eliminaLibro: 'Delete book',
  addPost: 'Add Post',
  nomeCognome: 'Name - Surname',
  descrizione: 'Description',
  modificaDati: 'Edit Data',
  cambiaImg: 'Change profile picture',
  modificaImg: 'Edit Photo',
  creaAccount: 'Create Account',
  placeholderNick: 'Enter Nickname',
  placeholderNomeCog: 'Enter Name and Surname',
  placeholderPass: 'Enter Password',
  nuovaPassword: 'New Password',
  placeholderEmail: 'Enter Email',
  crea: 'Create',
  commenti: 'Comments',
  elimina: 'Delete',
  commenta: 'Comments',
  cercaAccount: 'for account',
  messaggi: 'Messages',
  invia: 'Send',
  scrivi: 'Chat',
  cercaLibro: 'for Title',
  cercaAutore: 'for Author',
  autore: 'Author',
  titolo: 'Title',
  eliminaLista: 'Clear List',
  salva: 'Save',
  add: 'Add',
  selezionaImg: 'Select picture',
  eliminaImg: 'Delete picture',
  pubblica: 'Public',
  impostazioni: 'Settings',
  segnala: 'Report',
  cancella: 'Cancel',
  segui: 'Follow',
  seguiGia: 'Following',
  azioni: 'Actions',
  segnalaProblema: 'Report a Problem',
  SegnalazioneInviata: 'Report send',
  cancellaAccount: 'Delete account',
  notifiche: 'Notifications',
  modificaPassword: 'Update Password',
  modificaRiuscita: 'successful modification',
  resetPassword: 'Reset Password',
  passwordErrate: 'Incorrect Passwords',
  passwordSuccesful: 'Password changed',
  sendResetPassword: 'Email sent',
  copiaLista: 'Copy list',
  nomeLista: 'Name List',
  modificaPost: 'Edit',
  motificaDa: ' sent you a message',
  notificaDa: 'New message from ',
  notificaSegui: ' started following you',
  notificaMiPace: ' liked your post: ',
  notificaCommento: ' commented: ',
  si: 'Yes',
  postGlobali: 'global Post',
  nessunPost: 'No post',
  datiAggiornati: ' Data update',
  nickNonDisponibile: 'no Nickname available',
  emailNonVerificata: 'Email not verified',
  inviaNuovaEmail: 'Send a new Email',
  emailInviata: 'confirmation Email sent',
  categoria: 'Category',
  scriviReport: 'Write',
  cancellaNotifiche: 'Clear notifications',
  notificheCancellate: 'Notifications canceled',
  libri: 'Books',
  bloccaAccount: 'Account Block',
  accountBloccato: 'Account Blocked',
  sbloccaAccount: 'Unlock Account',
  accountSbloccato: 'Account Unlocked',
  avvisoBlocco: ' blocked you',
  riordinaPost: 'Reorganise Books',
  riordinaFilm: 'Reorganise Movie',
  riordinaSerieTv: 'Reorganise Series',
  riordinaVideogiochi: 'Reorganise  Games',
  riordinaLista: 'Reorganise List',
  selezionaLista: 'Seleziona lista',
  listaEliminata: 'deleted list',
  listaCreata: 'created list',
  eliminato: ' eliminato',
  aggiuntoAllaLista: ' added to the list ',
  aggiunto: ' added',
  visitaProfilo: 'Visit Profile',
  libriRiordinati: 'Reorganised books',
  filmRiordinati: 'Reorganised Movie',
  serieTvRiordinate: 'Reorganised Series',
  videogiochiRiordinati: 'Reorganised Video games',
  nessunaPersonaSeguita: 'No person followed',
  accediConProva: 'Log in with a trial account',
  social: 'Social network links',
  valutaApp: 'App rate',
  comunicazione: 'communicaion',
  libriDaImportare: 'Books to import',
  importaLibri: 'Import books',
  selezionaFileGoodreads: 'Select file - Goodreads',
  selezionaFileAnobii: 'Select file - Anobii',
  selezionaFileMyLibrary: 'Select File - MyLibrary',
  libriTrovati: 'Books found',
  film: 'Movie',
  serietv: 'TV Series',
  videogiochi: 'Videogames',
  data: 'Date',
  citazione: 'Quote',
  citazioni: 'Quotes',
  citazioneSalvata: 'Quote saved',
  rimuoviCitazione: 'Remove quote',
  creaCitazione: 'Create quote',
  riordinaCitazioni: 'Reorder quotes',
  citazioneEliminata: 'Quote deleted',
  duplicato: 'duplicate',
  richiestaDuplicati:
    'already present with this title. Do you want to proceed?',
  gioco: 'Videogame',
  ordinaPerId: 'Order by id',
  ordinaPerTitolo: 'Order by title',
  ordinaPerData: 'Order by date',
  ordinaPerAutore: 'Order by author',
  ordinaPerValuatazione: 'Order by rating',
  ordinaPerDataInizio: 'Order by start date',
  ordinaPerDataFine: 'Order by end date',
  ordinaPerCategoria: 'Order by category',
  addPreferiti: 'add to favourites',
  elPreferiti: 'remove from favorites',
  eliminaPreferiti: 'deleted from favorites',
  aggiuntoPreferiti: ' added to the favourites',
  registrati: 'Register',
  category: {
    History: 'History',
    Action: 'Action',
    Adventure: 'Adventure',
    Manga: 'Manga',
    Comics_Graphic_Novels: 'Comics & Graphic Novels',
    Classic: 'Classic',
    Science: 'Science',
    General: 'General',
    Crime: 'Crime',
    Fiction: 'Fiction',
    Drama: 'Drama',
    Fantasy: 'Fantasy',
    Horror: 'Horror',
    Mystery: 'Mystery',
    Romance: 'Romance',
    Satire: 'Satire',
    Science_Fiction: 'Science Fiction',
    Thriller: 'Thriller',
    Biography: 'Biography',
    Autobiography: 'Autobiography',
    Memoir: 'Memoir',
    Narrative: 'Narrative',
    Poetry: 'Poetry',
    Textbook: 'Textbook',
    Speech: 'Speech',
    Periodicals: 'Periodicals',
    Essay: 'Essay',
    Reference_Books: 'Reference Books',
    Self_help_Book: 'Self-help Book',
    Indie: 'Indie',
    RPG: 'RPG',
    Strategy: 'Strategy',
    Shooter: 'Shooter',
    Casual: 'Casual',
    Simulation: 'Simulation',
    Puzzle: 'Puzzle',
    Arcade: 'Arcade',
    Platformer: 'Platformer',
    Racing: 'Racing',
    Sports: 'Sports',
    Multiplayer_Massively: 'Multiplayer',
    Massively_Multiplayer: 'Multiplayer',
    Fighting: 'Fighting',
    Family: 'Family',
    Board_Games: 'Board Games',
    Educational: 'Educational',
    Card: 'Card',
    Soap: 'Soap',
    Reality: 'Reality',
    News: 'News',
    'Mini-Series': 'Mini-Series',
    Home_and_Garden: 'Home and Garden',
    Game_Show: 'Game Show',
    Food: 'Food',
    Documentary: 'Documentary',
    Comedy: 'Comedy',
    Children: 'Children',
    Animation: 'Animation',
    Sport: 'Sport',
    Suspense: 'Suspense',
    Talk_Show: 'Talk Show',
    Travel: 'Travel',
    Western: 'Western',
    Anime: 'Anime',
    Musical: 'Musical',
    Podcast: 'Podcast',
    War: 'War',
    Martial_Arts: 'Martial Arts',
    Awards_Show: 'Awards Show',
  },
  categoryGames: {
    Action: 'Action',
    Indie: 'Indie',
    Adventure: 'Adventure',
    RPG: 'RPG',
    Strategy: 'Strategy',
    Shooter: 'Shooter',
    Casual: 'Casual',
    Simulation: 'Simulation',
    Puzzle: 'Puzzle',
    Arcade: 'Arcade',
    Platformer: 'Platformer',
    Racing: 'Racing',
    Sports: 'Sports',
    Multiplayer_Massively: 'Multiplayer',
    Massively_Multiplayer: 'Multiplayer',
    Fighting: 'Fighting',
    Family: 'Family',
    Board_Games: 'Board Games',
    Educational: 'Educational',
    Card: 'Card',
  },
  categoryTvdb: {
    Soap: 'Soap',
    Science_Fiction: 'Science Fiction',
    Reality: 'Reality',
    News: 'News',
    'Mini-Series': 'Mini-Series',
    Horror: 'Horror',
    Home_and_Garden: 'Home and Garden',
    Game_Show: 'Game Show',
    Food: 'Food',
    Fantasy: 'Fantasy',
    Family: 'Family',
    Drama: 'Drama',
    Documentary: 'Documentary',
    Crime: 'Crime',
    Comedy: 'Comedy',
    Children: 'Children',
    Animation: 'Animation',
    Adventure: 'Adventure',
    Action: 'Action',
    Sport: 'Sport',
    Suspense: 'Suspense',
    Talk_Show: 'Talk Show',
    Thriller: 'Thriller',
    Travel: 'Travel',
    Western: 'Western',
    Anime: 'Anime',
    Romance: 'Romance',
    Musical: 'Musical',
    Podcast: 'Podcast',
    Mystery: 'Mystery',
    Indie: 'Indie',
    History: 'History',
    War: 'War',
    Martial_Arts: 'Martial Arts',
    Awards_Show: 'Awards Show',
  },
};

export default eng;
