import { type Color } from '@ionic/core';
import { type ToastButton, useIonToast } from '@ionic/react';
import { useCallback } from 'react';
const useToast = () => {
  const [present] = useIonToast();

  const presentToast = useCallback(
    async (
      message: string,
      color: Color = 'success',
      buttons?: Array<string | ToastButton> | undefined
    ) => {
      await present({
        message: message ?? '',
        duration: 1500,
        position: 'bottom',
        positionAnchor: 'my-tabbar',
        buttons,
        layout: 'stacked',
        color,
      });
    },
    [present]
  );
  return { presentToast };
};
export default useToast;
