const errorImgAvatar = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  e.currentTarget.src = require('../assets/img/avatar.jpg');
};
export default errorImgAvatar;

export const errorImgCover = (
  e: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  e.currentTarget.src = require('../assets/img/cover.png');
};
export const errorImgPost = (
  e: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  e.currentTarget.src = require('../assets/img/error.png');
};
