import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { messageError } from './api/clarityApi';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {
  initializeAdMob,
  isWeb,
  setContentSecurityPolicy,
} from './utility';
import { isPlatform } from '@ionic/core';
// import { StatusBar, Style} from '@capacitor/status-bar';

declare let ClarityPlugin: any;
const queryClient = new QueryClient();
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
if (!container) throw new Error('container not found');
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </QueryClientProvider>
);
setContentSecurityPolicy();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// https://web.dev/add-manifest/
serviceWorkerRegistration.register();
defineCustomElements(window);
// ClarityPlugin?.initialize("hcl5ntpr1o", success, failure, true);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// to do -> implementare per ios
reportWebVitals();
initializeAdMob();
if (!isWeb && !isPlatform('ios')) {
  ClarityPlugin?.initialize(
    'hcl5ntpr1o',
    () => {
    },
    messageError,
    true
  );
}
