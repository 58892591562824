import { atom } from 'recoil';
import type Notification from '../interface/Notification';
import { loadData } from './storage';

const atomNotifications = atom<Notification[]>({
  key: 'atomNotifications',
  default: [],
  effects: [loadData('atomNotifications')],
});

export default atomNotifications;
