import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { AuthContext } from '../store/context';
import { useMovePostState } from '../hooks/useMovePost';
import { Button } from 'primereact/button';
import { errorImgPost } from '../hooks/useErrorImg';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

import { Badge } from 'primereact/badge';
import { useLocation } from 'react-router-dom';
import useUploadPost from '../hooks/useUploadPost';

const MovePost: React.FC = () => {
  const [movePost, setMovePost] = useMovePostState();
  const auth = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  const { setUploadPost } = useUploadPost();
  const location = useLocation();

  const isPostsUserActive = useMemo(
    () => location?.pathname === `/posts/${auth.user.uid}/collection`,
    [location?.pathname, auth.user.uid]
  );
  const onSubmit = useCallback(async () => {
    if (movePost.posts) {
      for await (const val of movePost.posts) {
        setUploadPost([
          {
            post: val.post,
            ref: movePost.ref,
            idDocument: val.idDocument ?? '',
            originalRef: val.originalRef ?? '',
            deleteImg: val.func === 'move',
            copyRef: val.copyRef,
            copyRefByMyPost: val.copyRefByMyPost,
            oldDataFolder: val.oldDataFolder,
            copy: val.func === 'copy',
          },
        ]);
      }
      setMovePost({ ref: '' });
    }
  }, [movePost.posts, movePost.ref, setMovePost, setUploadPost]);

  const deletePostInMovePost = useCallback(
    async (index: number) => {
      if (movePost.posts) {
        const oldMovePosts = movePost.posts.slice();
        oldMovePosts.splice(index, 1);
        setMovePost({ ref: movePost.ref, posts: oldMovePosts });
      }
    },
    [movePost.posts, movePost.ref, setMovePost]
  );

  if (movePost?.posts?.[0]) {
    return (
      <>
        <div
          className={
            'p-2 pt-3 fixed  right-0 mt-6 z-5 text-200  text-lg ' +
            (expanded ? 'w-full' : 'w-auto')
          }
        >
          <div className="absolute left-0 top-0 mr-2 mt-2 z-2">
            <Badge
              value={movePost.posts.length}
              severity="info"
              className="text-lg"
            ></Badge>
          </div>
          <div className="absolute right-0 top-0 mt-1 z-2">
            <Button
              icon="pi pi-times"
              className=" p-button-outlined p-button-danger p-button-rounded w-2rem h-2rem"
              onClick={() => {
                setMovePost({ ref: '' });
              }}
            />
          </div>
          <span
            className={
              'absolute bottom-0 mb-1 z-2 ' + (expanded ? 'right-0' : 'left-0')
            }
          >
            <Button
              icon={expanded ? 'pi pi-arrow-right' : 'pi pi-arrow-left'}
              className="p-button-rounded w-2rem h-2rem mt-1 "
              onClick={() => {
                setExpanded(!expanded);
              }}
            />
          </span>
          <div className="movePost border-2 border-blue-500 border-round-xl p-2 ">
            <div>
              {expanded ? (
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {movePost.posts.map((val, index) => (
                    <SwiperSlide key={index} className="h-full">
                      <img
                        src={val.post.img}
                        onError={errorImgPost}
                        className="h-full w-full"
                      />
                      <div className="absolute left-0 top-0 ">
                        <Button
                          icon="pi pi-times"
                          className=" p-button-outlined p-button-danger p-button-rounded w-2rem h-2rem"
                          onClick={() => {
                            deletePostInMovePost(index);
                          }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <img
                  src={movePost.posts[0].post.img}
                  onError={errorImgPost}
                  className="w-6rem h-auto"
                />
              )}
            </div>
          </div>
        </div>
        {isPostsUserActive && (
          <div className="fixed bottom-0 z-2 mb-7 w-full text-center">
            <Button
              icon="pi pi-plus"
              className="  p-button-rounded "
              onClick={() => {
                onSubmit();
              }}
            />
          </div>
        )}
      </>
    );
  }

  return <></>;
};

export default memo(MovePost);
/**
            {expanded && (
              <div className="pl-2 w-full flex flex-column align-items-center ">
                <div className="flex flex-row w-full">
                  {location.pathname == "/profile/" + auth.user.uid &&
                    movePost.enableAddButton && (
                      <Button
                        label={language.aggiungi}
                        className="text-lg flex-1"
                        onClick={onSubmit}
                      />
                    )}
                </div>
              </div>
            )}
          <div className="absolute right-0 top-0 ">
            <Button
              icon="pi pi-times"
              className=" p-button-outlined p-button-danger p-button-rounded"
              onClick={() => setMovePost({ ref: "" })}
            />
          </div> */
