import { IonApp, setupIonicReact } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import 'primeflex/primeflex.css';
import 'react-advanced-cropper/dist/style.css';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import './App.scss';
import './style/webstyle.scss';
import AppRoutes from './routes/AppRoutes';
import { useLanguageValue } from './hooks/useLanguage';
import { AuthContext, LanguageContext } from './store/context';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useAuthValue } from './hooks/useAuth';
import { memo, useEffect } from 'react';
import useFirebase from './hooks/useFirebase';
import { addLocale } from 'primereact/api';
import { changeTheme } from './utility';
/* import { messageError, messageSuccess } from "./api/clarityApi";
//import { StatusBar, Style} from '@capacitor/status-bar';

declare let ClarityPlugin: any; */
setupIonicReact();

const App: React.FC = () => {
  const { initializeFirebase } = useFirebase();
  const language = useLanguageValue();
  const auth = useAuthValue();
  // StatusBar.hide();
  // StatusBar.setStyle({ style: Style.Dark });

  useEffect(() => {
    initializeFirebase();

    addLocale('it', {
      accept: 'Si',
      reject: 'No',
      choose: 'Scegli',
      upload: 'Carica',
      cancel: 'Annulla',
      dayNames: [
        'Domenica',
        'Lunedì',
        'Martedì',
        'Mercoledì',
        'Giovedì',
        'Venerdì',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'April',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      today: 'Oggi',
      clear: 'Chiaro',
      weekHeader: 'Sett.',
      firstDayOfWeek: 1,
      dateFormat: 'dd/mm/yy',
      weak: 'Debole',
      medium: 'Medio',
      strong: 'Forte',
      passwordPrompt: 'Inserisci la password',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!auth.authenticated) {
      changeTheme(false);
      document.documentElement.style.setProperty('--colorNav', 'white');
      document.documentElement.style.setProperty(
        '--backgroundNav',
        'linear-gradient(100deg, #7f70f5, #0ea0ff)'
      );
      document.documentElement.style.setProperty('--colorMenu', 'white');
      document.documentElement.style.setProperty(
        '--backgroundMenu',
        'linear-gradient(100deg, #7f70f5, #0ea0ff)'
      );
    }
  }, [auth.authenticated]);

  return (
    <IonApp>
      <LanguageContext.Provider value={language}>
        <AuthContext.Provider value={auth}>
          <ConfirmDialog/>
          <AppRoutes />
        </AuthContext.Provider>
      </LanguageContext.Provider>
    </IonApp>
  );
};

export default memo(App);
