import { initializeApp } from 'firebase/app';
import { setDataUserToDatabase } from '../api/authentications';
import { useCallback } from 'react';
import { type User, onAuthStateChanged, getAuth } from 'firebase/auth';
import { collection, doc, getDoc, getFirestore } from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import { useTokenToDatabase } from '../api/notificationsApi';
import type UserData from '../interface/UserData';
import { useAuthState } from './useAuth';

const useFirebase = () => {
  const [user, setUser] = useAuthState();
  const { mutate: setTokenToDatabase } = useTokenToDatabase(user?.user?.uid);

  const authChanger = useCallback(
    async (userData: User) => {
      const db = getFirestore();
      const document = doc(collection(db, 'profili'), userData.uid);
      const data = await getDoc(document);
      if (data.exists()) {
        if (user.user.uid !== userData.uid) {
          setUser({
            user: {
              uid: userData.uid,
              email: userData.email ? userData.email : '',
              emailVerified: userData.emailVerified,
            },
            authenticated: true,
          });
        }
      } else {
        await setDataUserToDatabase(
          userData.uid,
          userData.displayName ?? Math.random().toString(36).substr(2, 9),
          userData.email ?? '',
          'it',
          userData.displayName ?? Math.random().toString(36).substr(2, 9)
        );
        setTimeout(() => {
          setUser({
            user: {
              uid: userData.uid,
              email: userData.email ?? '',
              emailVerified: userData.emailVerified,
            },
            authenticated: true,
          });
        }, 1000);
      }
    },
    [setUser, user.user.uid]
  );

  const initializeFirebase = useCallback(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyD-DWtOaI4P_D4IoLFOStnYptmfJK2B-I0',
      authDomain: 'appbook-bf2d3.firebaseapp.com',
      databaseURL: 'https://appbook-bf2d3.firebaseio.com',
      projectId: 'appbook-bf2d3',
      storageBucket: 'appbook-bf2d3.appspot.com',
      messagingSenderId: '106950929113',
      appId: '1:106950929113:web:1fe18a3155b7246d6becc8',
      measurementId: 'G-CMZ44LF1LL',
    };

    initializeApp(firebaseConfig);
    onAuthStateChanged(getAuth(), (userData) => {
      if (userData) {
        authChanger(userData);
      } else {
        setUser({
          user: {
            uid: '',
            email: '',
            emailVerified: false,
          },
          authenticated: false,
        });
      }
    });
  }, [authChanger, setUser]);

  const getTokenForMessaging = useCallback(async () => {
    const messaging = getMessaging();
    return await getToken(messaging, {
      vapidKey:
        'BLtaH0hqeDxQdt1418U9eznzou79FQTdrBx70YFGEdF4xs4SB9lMeZJD7oXBqhRgTqAs2zE3Jv95BiYQU7-XqN0',
    });
  }, []);

  const requestTokenForMessaging = useCallback(
    (userData: UserData) => {
      try {
        Notification.requestPermission().then((permission) => {
          if (permission === 'denied') {
            throw new Error('User denied permissions!');
          }
          getTokenForMessaging().then((currentToken) => {
            if (currentToken && userData?.settings?.token !== currentToken) {
              // Send the token to your server and update the UI if necessary
              setTokenToDatabase({
                token: currentToken,
                uid: userData?.dati?.uid,
              });
            }
          });
        });
      } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Error');
      }
    },
    [getTokenForMessaging, setTokenToDatabase]
  );

  return {
    initializeFirebase,
    getTokenForMessaging,
    useTokenToDatabase,
    requestTokenForMessaging,
  };
};
export default useFirebase;
