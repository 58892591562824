import {
  useMutation,
  useQuery,
  type UseQueryResult,
} from '@tanstack/react-query';
import {
  getFirestore,
  getDoc,
  collection,
  doc,
  setDoc,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import type UserData from '../interface/UserData';

interface BlockedAccount {
  uidRequest: string;
  uid: string;
} /*
function divideArray(inputArray: string[], chunkSize: number): string[][] {
  const dividedArrays: string[][] = [];
  for (let i = 0; i < inputArray.length; i += chunkSize) {
    const chunk = inputArray.slice(i, i + chunkSize);
    dividedArrays.push(chunk);
  }
  return dividedArrays;
}

const getEmail = async () => {
  const db = getFirestore();
  const document = collection(db, "profili");
  const user = await getDocs(query(document, orderBy("info.data")));
  const email = user?.docs?.map((doc) => doc.data()?.dati?.email);
  const originalArray: string[] = email;
  const chunkSize: number = 50;

  const dividedArrays: string[][] = divideArray(originalArray, chunkSize);
  console.log(dividedArrays)
}; */

const getUserData = async (uid: string) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  const data = await getDoc(document);
  // getEmail();
  return {
    ...data.data(),
  };
};

const setUserData = async (user: UserData) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), user.dati.uid);
  await setDoc(document, user);
};

const getBlockedAccount = async ({ uidRequest, uid }: BlockedAccount) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uidRequest);
  const q = query(
    collection(document, 'accountBloccati'),
    where('uid', '==', uid)
  );
  const res = await getDocs(q);
  if (!res.empty) {
    return true;
  }
  return false;
};

const setBlockAccount = async ({ uidRequest, uid }: BlockedAccount) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uidRequest);
  await addDoc(collection(document, 'accountBloccati'), {
    uid,
  });
  return true;
};

const setUnlockAccount = async ({ uidRequest, uid }: BlockedAccount) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uidRequest);
  const q = query(
    collection(document, 'accountBloccati'),
    where('uid', '==', uid)
  );
  const res = await getDocs(q);
  if (!res.empty) {
    await deleteDoc(doc(document, 'accountBloccati', res.docs[0].id));
  }
  return true;
};
/*
interface Settings {
  notifiche: boolean;
  pubblicità: boolean;
}

const getSettingsAd = async (uid: string):Promise<Settings> => {
  const db = getFirestore();
  const document = doc(collection(db, "profili"), uid);
  const ad = doc(document, "settings/pubblicità");
  const not = doc(document, "settings/notifiche");
  const data = await getDoc(ad);
  return {
    ...data.data(),
  };
};

const getSettingsNotifications = async (uid: string) => {
  const db = getFirestore();
  const document = doc(collection(db, "profili"), uid);
  const data = await getDoc(settings);
  return {
    ...data.data(),
  };
}; */

export const changeLanguage = (uid: string, lang: string) => {
  const db = getFirestore();
  const document = doc(collection(db, 'profili'), uid);
  updateDoc(document, {
    'info.lingua': lang,
  });
};

export const useQueryUserData = (
  uid: string,
  enabled?: boolean
): UseQueryResult<UserData> => {
  return useQuery(
    ['userData', uid],
    async () => {
      if (!uid) {
        throw new Error('Account not found');
      }
      return await getUserData(uid);
    },
    { enabled, retry: false }
  );
};
export const useQueryUserDataProfileTab = (
  uid: string
): UseQueryResult<UserData> => {
  return useQuery(
    ['userDataProfileTab', uid],
    async () => {
      if (!uid) {
        throw new Error('Account not found');
      }
      return await getUserData(uid);
    },
    { refetchInterval: false, retry: false }
  );
};

export const useQueryGetBlockedAccount = (
  props: BlockedAccount
): UseQueryResult<boolean> => {
  return useQuery(
    ['getBlockedAccount', props.uid, props.uidRequest],
    async () => {
      if (!props.uid || props.uid === props.uidRequest) {
        return false;
      }
      return await getBlockedAccount(props);
    },
    { refetchInterval: false }
  );
};

export const useMutationSetUserData = () => {
  return useMutation(async (user: UserData) => {
    await setUserData(user);
  });
};

export const useMutationSetBlockAccount = () => {
  return useMutation(async (props: BlockedAccount) => {
    return await setBlockAccount(props);
  });
};

export const useMutationSetUnlockAccount = () => {
  return useMutation(async (props: BlockedAccount) => {
    return await setUnlockAccount(props);
  });
};
