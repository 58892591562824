import { atom } from 'recoil';
import type UserData from '../../interface/UserData';
import { loadData } from '../storage';

const atomUserData = atom<UserData | null>({
  key: 'atomUserData',
  default: null,
  effects: [loadData('userData')],
});

export default atomUserData;
